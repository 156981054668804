<template>
  <div
    class="message"
    :class="{own: message.type == 0,  sales: (message.sale_id), deposit: (message.deposit_id), utility: (message.utility_order_id),  o: (message.withdrawal_id || (message.type == 0 && (message.status_message == 1))), o2: message.type == 0 && message.status_message == 3, attendance: message.attendance_id, not_sended: message.type == 0 && message.status_message == 0}"
  >
    <div
      class="username"
      v-if="showMessageName(message, index)"
    >{{ getMessageContactName(message) }}</div>
    <div class="margin-top: 5px"></div>
    <div
      class="content elevation-3"
      :id="!reference ? message.message_id : ''"
      :class="{reference: (reference == true), deleted: message.status_message == 6}"
    >
      <reference-message
        v-if="reference == false && message.references && message.references.length != 0"
        :contact_name="getMessageContactName(message.references[0])"
        :isGroup="isGroup"
        :message_reference="message.references[0]"
        :openedChat="openedChat"
        :reference_id="message.reference_id"
        :type="message.type"
      ></reference-message>
      <chat-image
        v-if="message.image_base64"
        :imgsrc="message.image_base64"
        :thumbsrc="message.image_base64"
      ></chat-image>
      <chat-document
        v-else-if="message.document_name"
        :mimetype="message.document_mimetype"
        :content="message.document_name"
        :docsrc="message.document_thumb"
        :thumbsrc="message.document_base64"
        :message_id="message._id"
        :is_group="message.is_group"
      ></chat-document>
      <chat-audio v-else-if="message.audio_base64" :audiourl="message.audio_base64"></chat-audio>
      <chat-contact
        :contact_id="message.send_contact_id"
        :contact_name="message.send_contact_name"
        v-else-if="message.send_contact_id"
      ></chat-contact>
      <div
        v-if="!message.document_thumb"
        class="message-content"
        v-html="getMessageContent(message)"
      >
      
      </div>
      <div class="text-deleted-message" v-if="message.status_message == 6">
        <hr class="hr-deleted-message">
        <b>Mensagem apagada no whatsapp</b>
      </div>
      <div class="message-data">
        <v-menu bottom :left="message.type == 0" :right="message.type != 0" offset-x>
          <template v-slot:activator="{ on }">
            <div class="hour" v-on="on">
              {{getMessageHour(message)}}
              <v-icon
                x-small
                v-if="message.attendance_id && isGroup"
              >mdi-checkbox-multiple-marked-circle-outline</v-icon>
              <v-icon
                x-small
                v-if="message.type == 0 && message.status_message != 0"
              >{{ getStatusIcon(message) }}</v-icon>
            </div>
          </template>
          <v-list v-if="parent == 'chat' || parent == 'audit'">
            <v-list-item
              @click="showDialogForwardMessage({show: true, message: message, instance_id: openedChat.instance_id, forward_mode: 'MESSAGE'})"
            >
              <v-list-item-title>Encaminhar Mensagem</v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="parent == 'chat' || parent == 'audit'"
              @click="setReplyMessage({message: message})"
            >
              <v-list-item-title>Responder</v-list-item-title>
            </v-list-item>

            <v-list-item 
              v-if="(!message.deposit_id || message.deposit_id === 0) && this.selected_messages.length == 0 && parent == 'chat'"
              @click="depositTwoMessages(message)"
            >
              <v-list-item-title>Depósito com Comprovante</v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="(!message.deposit_id || message.deposit_id === 0) && parent == 'chat'"
              @click="deposit(message, {contact: openedChat.contact_name ? openedChat.contact_name : openedChat.contact_id, instance_name: openedChat.instance_name, contact_id: openedChat.contact_id, instance_id: openedChat.instance_id, is_group: openedChat.is_group})"
            >
              <v-list-item-title>{{ this.selected_messages.length == 0 ? 'Depósito sem Comprovante' : 'Depósito'}}</v-list-item-title>
            </v-list-item>

            
            <v-list-item
              v-if="(message.deposit_id && message.deposit_id != 0) && parent == 'chat'"
              @click="cancelDeposit({message: message})"
            >
              <v-list-item-title>Cancelar Depósito</v-list-item-title>
            </v-list-item>

                      
            <v-list-item
              v-if="(message.deposit_id && message.deposit_id != 0) && parent == 'chat'"
              @click="editDeposit({message: message})"
            >
              <v-list-item-title>Editar Depósito</v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="(message.deposit_id && message.deposit_id != 0) && (parent == 'chat' || parent == 'audit') "
              @click="viewDeposit({message: message})"
            >
              <v-list-item-title>Visualizar Depósito</v-list-item-title>
            </v-list-item>

            <v-list-item 
              v-if="this.selected_messages.length >= 1 && parent == 'chat'"
              @click="cancelDepositTwomessages(message)"
            >
              <v-list-item-title>Cancelar Seleção</v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="(message.withdrawal_id == 0 || !message.withdrawal_id) && parent == 'chat' && checkProfileAcessWithdrawalLeader()"
              @click="createWithdrawal({message: message, contact: openedChat.contact_name ? openedChat.contact_name : openedChat.contact_id, phone: openedChat.contact_id, idInstance: openedChat.instance_id, screen: 'chat', sendReceipt: openedChat.description.sendReceipt})"
            >
             <v-list-item-title>Criar Saque</v-list-item-title>
            </v-list-item>

            <v-list-item v-if="false"
              @click="createAnnotated({contact : openedChat.contact_id})"
            >
              <v-list-item-title>Criar Anotado</v-list-item-title>
            </v-list-item>

             <v-list-item
              v-if="(!message.vip_sale_id || message.vip_sale_id === 0) && parent == 'chat'"
              @click="createVipSale({contact : openedChat.contact_id})"
            >
              <v-list-item-title>Criar Compra de Item</v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="(message.withdrawal_id && message.withdrawal_id != 0) && parent == 'chat'"
              @click="editWithdrawal({message: message,  contact: openedChat.contact_name ? openedChat.contact_name : openedChat.contact_id, instance_name: openedChat.instance_name})"
            >
              <v-list-item-title>Editar Saque</v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="message.withdrawal_id && message.withdrawal_id != 0 && parent == 'chat'"
              @click="cancelWithdrawal({message: message})"
            >
              <v-list-item-title>Cancelar Saque</v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="message.sale_id && message.sale_id != 0 && parent == 'chat'"
              @click="editSale({message: message,  contact: openedChat.contact_name ? openedChat.contact_name : openedChat.contact_id, instance_name: openedChat.instance_name})"
            >
              <v-list-item-title>Editar Compra</v-list-item-title>
            </v-list-item>

            <!--v-list-item
              v-if="(!message.deposit_id || message.deposit_id === 0) && parent == 'chat'"
              @click="getRakesPass({message: message})"
            >
              <v-list-item-title>Adiantar Rakeback</v-list-item-title>
            </v-list-item -->

            <!-- <v-list-item
              v-if="(!message.utility_order_id || message.utility_order_id === 0) && parent == 'chat'"
              @click="utilityOrder(message)"
            >
              <v-list-item-title>Ordem Auxiliar</v-list-item-title>
            </v-list-item> -->

            <v-list-item
              v-if="(message.utility_order_id && message.utility_order_id != 0) && (parent == 'chat' || parent == 'audit') "
              @click="viewUtilityOrder({ message })"
            >
              <v-list-item-title>Visualizar Ordem</v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="(message.withdrawal_id == 0 || !message.withdrawal_id) && parent == 'chat'"
              @click="registerFail({message: message, contact: openedChat.contact_name ? openedChat.contact_name : openedChat.contact_id, contact_id: openedChat.contact_id, instance_id: openedChat.instance_id, instance_name: openedChat.instance_name})"
            >
              <v-list-item-title>Registrar Erro</v-list-item-title>
            </v-list-item>

          </v-list>

        </v-menu>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import Audio from "./Audio.vue";
import ContactCard from "./ContactCard.vue";
import ReferenceMessage from "./ReferenceMessage.vue";
import Document from "./Document.vue";
import Image from "./Image.vue";

export default {
  name: "message",
  props: [
    "contact_name",
    "index",
    "is_checked",
    "isGroup",
    "lastMessage",
    "message",
    "openedChat",
    "parent",
    "reference",
  ],
  components: {
    "chat-audio": Audio,
    "chat-contact": ContactCard,
    "chat-document": Document,
    "chat-image": Image,
    "reference-message": ReferenceMessage,
  },
  computed: {
    ...mapState("login", ["userData"]),
    ...mapFields("message", ["selected_messages"]),
  },
  methods: {
    ...mapGetters('login', ['checkProfileAcessWithdrawalLeader']),
    ...mapMutations("chat", ["setReplyMessage"]),
    ...mapMutations("message", ["showDialogForwardMessage", "setSelectedMessages"]),
    ...mapMutations("vip_sales", {addSale: "add"}),
    ...mapActions("chat", [
      "getRakesPass",
      "getChipsTransfer",
      "getInfoRegister",
      "finishAttendance",
      "forwardReceipt",
      "startAttendance"
    ]),
    ...mapActions("message", [
      "cancelWithdrawal",
      "cancelDeposit",
      "editDeposit",
      "editSale",
      "registerFail",
      "createWithdrawal",
      "createAnnotated",
      "createVipSale",
      "createDeposit",
      "createSale",
      "editWithdrawal",
      "createPlayer",
      "viewDeposit",
      "createUtilityOrder",
      "viewUtilityOrder",
    ]),
    cancelDepositTwomessages() {
      this.setSelectedMessages({messages: []})
    },
    selectTwoMessages(message, payload) {
      if(this.selected_messages.length == 0) {
        this.selected_messages.push(message)
      } else {
        this.selected_messages.push(message)
        this.createSale(payload)
      }
    },
    depositTwoMessages(message) {
      this.selected_messages.push(message)
    },
    deposit(message, payload) {
      this.selected_messages.push(message)
      this.createDeposit(payload)
    },
    utilityOrder(message){
      this.selected_messages.push(message)
      this.createUtilityOrder()    
    },
    getMessageContent(message) {
      if(typeof(message.content) == "string" && message.content.length > 0) {
        var messageTreated = message.content
          .replace(/&/g, "&amp;")
          .replace(/</g, "&lt;")
          .replace(/>/g, "&gt;")
          .replace(/"/g, "&quot;")
          .replace(/'/g, "&#039;")
          .replace(/\n/g, "<br>")
          .replace(/\*([^]+?)\*/g, "<b>$1</b>");
        return messageTreated;
      } 

      return ""
    },
    getMessageHour(message) {
      return new Date(message.date).toLocaleTimeString().substr(0, 5);
    },
    getMessageContactName(message) {
      if (this.isGroup == false) {
        return message.type == 0
          ? message.contact_name
            ? message.contact_name
            : "-"
          : this.openedChat.contact_name
          ? this.openedChat.contact_name
          : this.openedChat.contact_id;
      } else {
        return message.type == 0
          ? message.contact_name
            ? message.contact_name
            : "-"
          : message.contact_name
          ? message.contact_name
          : message.participant_phone;
      }
    },
    getStatusIcon(message) {
      if (message.status_message == 1) {
        return "mdi-check";
      } else if (message.status_message == 2) {
        return "mdi-check-all";
      } else if (message.status_message == 6) {
        return "mdi-delete";
      } else { 
        return "mdi-clock";
      }
    },
    showMessageName(message, index) {
      if (this.isGroup == false) {
        return (
          message.type != this.lastMessage.type ||
          message.contact_id != this.lastMessage.contact_id ||
          index == 0 ||
          this.reference
        );
      } else {
        return (
          message.type != this.lastMessage.type ||
          message.contact_id != this.lastMessage.contact_id ||
          index == 0 ||
          message.participant_phone != this.lastMessage.participant_phone ||
          this.reference
        );
      }
    },
    finishAt(message) {
      var payload = {
        type: "message",
        message: message,
      };
      this.finishAttendance(payload);
    },
    showFinishAttendance(message) {
      if (this.isGroup && message.attendance_id != 0) {
        var openAttendance = message.attendances.filter((a) => {
          return a.status_attendance == 1;
        });
        return openAttendance.length != 0;
      }
      return false;
    },
    scrollToEnd() {
      this.$nextTick(() => {
        var container = document.querySelector(".messages-container");
        container.scrollTop = container.scrollHeight;
      });
    },
  },
};
</script>

<style>
@import "./css/message.css";
</style>
