export const url = window.location.hostname.replace("comercial.", "");
export const completeUrl = window.location.origin
export const isLocalHost = (url) => {
    if (url.indexOf("127.0.0.1") != -1 || url.indexOf("localhost") != -1 || url.indexOf("192.168") != -1) {
        return true
    } else {
        return false
    }
}

export const isHomolog = (url) => {
    return url.indexOf("homolog") != -1
}
let users = ""
let ws = ""
let endpointChat = ""
let financial = ""
let commercial = ""
let agents = ""
let players = ""
let store = ""
let internal = ""
let ordersApi = ""
let fichas24h = ""

const protocolHttp = isLocalHost(url) ? "http://" : "https://"
const protocolWs = isLocalHost(url) ? "ws://" : "wss://"


if (isLocalHost(url)) {
    users = protocolHttp + url + ":8079/api/auth"
    ws = protocolWs + url + ":8088/ws/chat"
    endpointChat = protocolHttp + url + ':8088/api/chat'
    financial = protocolHttp + url + ":8083/api/financial"
    commercial = protocolHttp + url + ":8085/api/commercial"
    agents = protocolHttp + url + ":8092/api/v1/agents"
    players = protocolHttp + url + ":8086/api/players"
    store = protocolHttp + url + ":8087/api/store"
    internal = protocolHttp + url + ":8089/api/internal"
    ordersApi = protocolHttp + url + ":8097/api/v1/orders"

    fichas24h = protocolHttp + url + ":3050"
} else if (isHomolog(url)) {
    users = protocolHttp + url + "/api/auth"
    ws = protocolWs + url + "/ws/chat"
    endpointChat = protocolHttp + url + '/api/chat'
    financial = protocolHttp + url + "/api/financial"
    commercial = protocolHttp + url + "/api/commercial"
    agents = protocolHttp + url + "/api/v1/agents"
    players = protocolHttp + url + "/api/players"
    store = protocolHttp + url + "/api/store"
    internal = protocolHttp + url + "/api/internal"
    ordersApi = protocolHttp + url + "/api/v1/orders"
    fichas24h = protocolHttp + "apis.homologacao.fichas24horas.com.br"
} else {

    let prodUrl = "sistema.sxgrupo.com.br"
    let awsUrl = "sistemas.sxgrupo.com.br"
    let chatUrl = "chat.sxgrupo.com.br"
    users = protocolHttp + awsUrl + "/api/auth"
    ws = protocolWs + chatUrl + "/ws/chat"
    endpointChat = protocolHttp + chatUrl + '/api/chat'
    financial = "https://sistemas.sxgrupo.com.br/api/financial"
    commercial = protocolHttp + awsUrl + "/api/commercial"
    //  agents = protocolHttp + prodUrl + "/api/v1/agents"
    players = protocolHttp + prodUrl + "/api/players"
    store = protocolHttp + prodUrl + "/api/store"
    internal = protocolHttp + awsUrl + "/api/internal"
    ordersApi = "https://orders.sxgrupo.com.br/api/v1/orders"
    fichas24h = protocolHttp + "apis.fichas24horas.com.br"
}

const slackApi = "https://slack.com/api"
const tomTicketApi = "https://api.tomticket.com"

const endPoints = {
    aws: fichas24h + "/aws",
    config_clubs: fichas24h + "/config_clubs",
    auto_attendance: fichas24h + "/auto-attendance",
    annotateds: financial + "/annotateds",
    absences: internal + "/absences",
    agent_manager: commercial + "/agents_manager",
    agent_slots: commercial + "/agent_slots",
    agents: commercial + "/agents",
    agents_closure: financial + "/pppoker/agents_closure",
    agents_closure_dates: financial + "/pppoker/agents_closure/dates",
    agents_folders: commercial + "/agents_folders",
    agents_groups: commercial + "/agents_groups",
    agents_tree: commercial + "/agents_tree",
    attendance_dashboard: endpointChat + "/dashboard",
    attest: internal + "/attest",
    annotated: financial + "/annotated",
    automatic_message: endpointChat + "/automatic_messages",
    avaliations: endpointChat + "/avaliations",
    banks: financial + "/bank",
    banks_bacen: financial + "/banks",
    bank_sub_accounts: fichas24h + "/sub_accounts",
    billings: financial + "/billings",
    bills_to_receive: financial + "/bills_to_receive",
    bills_to_pay: financial + "/bills_to_pay",
    bills_items: financial + "/bills_items",
    balance: financial + "/balance",
    balance_agent: commercial + "/agents/balance",
    balance_agent_club: commercial + "/agents/balance_club",
    balance_player: players + "/players/balance",
    balance_player_club: players + "/players/balance_club",
    bots: ordersApi + "/bots",
    bonus: endpointChat + "/bonus",
    clients: commercial + "/clients",
    clients_registrations: commercial + "/clients_registrations",
    client_apps: commercial + "/client_apps",
    clubs: fichas24h + "/clubs",
    conciliation: financial + "/conciliation",
    cep: "https://ws.apicep.com/cep.json",
    collaborators: internal + "/collaborators",
    childrens: internal + "/childrens",
    collaborators_equipments: internal + "/collaborators_equipments",
    collaborator_documents: internal + "/collaborator_documents",
    companies: internal + "/companies",
    contact: endpointChat + "/contact",
    contact_list: endpointChat + "/contact_list",
    cost_center: financial + "/cost_center",
    chips_transfer: financial + "/chips_transfer",
    clients_drx: financial + "/clients_drx",
    deposits: financial + "/deposit",
    departments: internal + "/departments",
    documents: commercial + "/documents",
    documents_player: players + "/documents",
    emails: internal + "/emails",
    emails_sent: internal + "/emails_sent",
    equipments: internal + "/equipments",
    expenses: financial + "/expense",
    expenses_recurrent: financial + "/expense_recurrent",
    expense_types: financial + "/expense_types",
    extract: financial + "/extract",
    external_login_url: 'https://loginsx.flutterflow.app',
    homolog_external_login_url: 'https://loginsx.flutterflow.app',
    fails: financial + "/fails",
    financial_bots: financial + "/bots",
    financial_check: financial + "/financial_check",
    financial_conciliation: financial + "/financial_conciliation",
    financial_dashboad: financial + "/dashboard",
    financial_monitoring: financial + "/financial_moviments/monitoring",
    financial_moviments: financial + "/financial_moviments",
    financial_solicitations: financial + "/financial_solicitations",
    gains_loses: financial + "/gains_loses",
    httpchats: endpointChat + "/chats",
    instances: endpointChat + "/instances",
    jobs: internal + "/jobs",
    candidates: internal + "/candidates",
    league: internal + "/league",
    occurrence: endpointChat + '/occurences',
    league_closure: financial + "/pppoker/league_closure",
    league_closure_club: financial + "/pppoker/league_closure/club",
    league_closure_club_detail: financial + "/pppoker/league_closure/club/detail",
    login: users + "/oauth/access_token",
    managers: commercial + "/managers",
    menus: users + "/menus",
    notifications_schedule: fichas24h + "/notifications",
    oauth: users + "/oauth",
    orders: ordersApi,
    utilities: ordersApi + "/utilities",
    items: ordersApi + "/items",
    periods_closure_transactions: financial + "/pppoker/period_closure/periods_closure_transactions",
    parameters_agent_closure: financial + '/pppoker/parameters_agent_closure',
    payments_annotateds: financial + "/payments_annotateds",
    player_history: financial + '/player_history',
    players: players + '/players',
    players_agent: commercial + '/agents/players',
    players_address: players + '/address',
    players_accounts: players + '/accounts',
    player_approval: internal + '/player_approval',
    players_balance: financial + '/players_balance',
    periods_players_balance: financial + '/periods_players_balance',
    products: store + '/products',
    profiles: users + '/profiles',
    profile_permissions: users + '/profile_permissions',
    routes: users + '/routes',
    roles: internal + "/roles",
    rakeback: commercial + "/rakeback",
    rake_deals: commercial + "/rake_deals",
    receipt: financial + "/receipt",
    receipts: financial + "/receipts",
    pix: financial + "/pix",
    pix24h: fichas24h + "/pix",
    releases: internal + "/releases",
    requests: internal + "/requests",
    requests_interactions: internal + "/requests_interactions",
    meetings: internal + "/meetings",
    pppoker_financial: financial + "/pppoker",
    period_closure: financial + "/pppoker/period_closure",
    period_closure_dates: financial + "/pppoker/period_closure/dates",
    sales: store + "/sales",
    sales_items: store + "/sales_items",
    slack_api: slackApi,
    slots: internal + "/slots",
    slots_revenue: financial + "/slots_revenue",
    system_services: internal + "/system_services",
    status_service: internal + "/status_service",
    subAgents: commercial + "/subagents",
    stock: store + "/stock",
    transactions_check: financial + "/transactions_check",
    tom_ticket: internal + "/tomticket",
    tom_ticket_api: tomTicketApi,
    agent_users: agents + "/agents_users",
    users: users + "/users",
    //----------------------------------------------------------------
    vip_sales: financial + "/vip_sales",
    weekly_revenue: financial + "/weekly_revenue",
    withdrawal: financial + "/withdrawal",
    withdrawals24h: fichas24h + "/withdrawals",
    withdrawals_blacklist: financial + "/withdrawals_blacklist",
    wschats: ws + "/chats",
};

export default endPoints;