<template>
<div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    
    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >
        {{ notificationMessage }}
    </v-snackbar>
    <v-toolbar flat class="elevation-5">
      <div>
        <v-toolbar-title>Saques</v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      <div v-if="profileAccess()"><b> Valor Total: {{ this.totalValue ? parseFloat(this.totalValue).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 0 }}</b></div>
      <v-spacer></v-spacer>
      <div v-if="profileAccess()"><b> Saques em Aberto: {{ this.withdrawalTotals.total_open_value ? this.withdrawalTotals.total_open_value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 0 }}</b></div>
      <v-spacer></v-spacer>
      <div><b>Tempo Médio: {{avg_time}} </b></div>
      <v-spacer></v-spacer>
      <div class="button-toolbar">
        <v-tooltip bottom v-if="profileAccess()">
          <template v-slot:activator="{on, attrs}">
            <v-btn color="red" dark class="ma-2" @click="restart24hService()" v-bind="attrs" v-on="on" small>
              <v-icon>mdi-alert</v-icon>
            </v-btn>
          </template>
          <span>Reiniciar Serviço</span>
        </v-tooltip> 

        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">
            <v-btn color="black" dark class="ma-2" @click="downloadXlsx({show: true})" v-bind="attrs" v-on="on" small>
              <v-icon>mdi-file-excel</v-icon>
            </v-btn>
          </template>
          <span>Baixar XLSX</span>
        </v-tooltip>  

        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">
            <v-btn color="black" dark class="ma-2" @click="showFilter({show: true})" v-bind="attrs" v-on="on" small>
              <v-icon :color="filtered ? 'grey' : 'white'">mdi-filter</v-icon>
            </v-btn>
          </template>
          <span>Filtro</span>    
        </v-tooltip>  
        
        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">
              <v-btn color="black" dark class="ma-2" @click="getItems" v-bind="attrs" v-on="on" small><v-icon>mdi-refresh</v-icon></v-btn>
          </template>
          <span>Recarregar</span>    
        </v-tooltip> 

        <v-tooltip bottom v-if="this.userData.role == 93 || this.userData.role == 97 || this.userData.role == 98 || this.userData.role == 99">
          <template v-slot:activator="{on, attrs}">
            <v-btn color="black" dark class="ma-2" v-if="profileAccess()" v-bind="attrs" v-on="on" @click="add({screen: 'withdrawal'})" small><v-icon>mdi-plus</v-icon></v-btn>
          </template>
          <span>Adicionar Saque</span>    
        </v-tooltip> 
      </div> 
    </v-toolbar>


    <v-data-table
      :headers="headers"
      :loading="loading"
      :items="items"
      :options.sync="pagination"
      :server-items-length="totalItems"
      :item-class= "getClassColor"
      class="elevation-10 table-margin"
      no-data-text="Nenhum saque encontrado"
      show-expand
      :footer-props="{
        'items-per-page-options': [10, 20, 50, 100],
        'items-per-page-text': 'Itens por página',
        'show-first-last-page': true,
        'page-text': `Página ${pagination.page} de ${parseInt(totalItems/pagination.itemsPerPage) + 1}`
      }" 
      :expanded.sync="expanded"
    >
      <template v-slot:[`item.idClient`]="{ item }">
        <v-chip :color="item.idClient ? 'green' : 'red'" class="ma-2" text-color="white" label>{{ item.idClient ? "Sim" : "Não"}}</v-chip>
      </template>
      <template v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }">
        <v-icon @click="expand(true)" v-if="(item.pixKey || item.observation) && !isExpanded">mdi-chevron-down</v-icon>
        <v-icon @click="expand(false)" v-if="(item.pixKey || item.observation) && isExpanded">mdi-chevron-up</v-icon>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td :colspan="(headers.length/2)">
          Chave Pix: {{ item.pixKey }}
          <br>
          {{ item.observation && "Observação: " + item.observation }}
        </td>
      </template>
      <template v-slot:[`item.requestedValue`]="{ item }">
        <span>{{ getCurrency(item.requestedValue) }}</span>
      </template>
      <template v-slot:[`item.value`]="{ item }">
        <span>{{ (item.value) ? item.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 0 }}</span>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        <span>{{ getDate(item.createdAt) }}</span>
      </template>
      <template v-slot:[`item.approvalAt`]="{ item }">
        <span>{{ getDate(item.approvalAt) }}</span>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip label :color="getColorByStatus(item.status)" text-color="white">{{ getStatus(item.status) }}</v-chip>
      </template>
      <template v-slot:[`item.confidence`]="{ item }">
        <v-chip :color="getColorByConfidenceRate(item.confidence)" class="ma-2" text-color="white" label>{{ item.confidence }}</v-chip>
      </template>
      <template v-slot:[`item.sended`]="{ item }">
        <span>{{ getSendedStatus(item.sended) }}</span>
      </template>
      <template v-slot:[`item.typeSend`]="{ item }">
        <v-chip :color="getColorByTypeSend(item.typeSend)" class="ma-2" :text-color="item.typeSend != 0 ? 'white': 'black'" label>{{ getSendType(item.typeSend) }}</v-chip>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        <span>{{ getTypeDescription(item.type) }}</span>
      </template>
      <template v-slot:[`item.inscription`]="{ item }">
        <span>{{ getIsEmpty(item.inscription) }}</span>
      </template>
      <template v-slot:[`item.agency`]="{ item }">
        <span>{{ getIsEmpty(item.agency) }}</span>
      </template>
      <template v-slot:[`item.account`]="{ item }">
        <span>{{ getIsEmpty(item.account) }}</span>
      </template>
      <template v-slot:[`item.finishAt`]="{ item }">
        <span>{{ getTimeWithdrawal(item.createdAt, item.finishAt) }}</span>
      </template>
      <template v-slot:[`item.checkBonus`]="{ item }">
        <v-chip :color="item.checkBonus ? 'red' : 'green'" class="ma-2" text-color="white" label>{{ getCheckBonus(item.checkBonus) }}</v-chip>
      </template>
      <template v-slot:[`item.inscription`]="{ item }">
        <v-chip :color="item.inscription.lenght > 11 ? '#8B5742' : 'blue'" class="ma-2" text-color="white" label>{{ item.inscription.lenght > 11 ? "CNPJ" : "CPF" }}</v-chip>
      </template>
      <template v-slot:[`item.passedForApproval`]="{ item }">
        <v-chip :color="item.passedForApproval ? 'red' : 'green'" class="ma-2" text-color="white" label>{{ getCheckBonus(item.passedForApproval) }}</v-chip>
      </template>
      <template v-slot:[`item.payedAutomatic`]="{ item }">
        <v-chip :color="item.payedAutomatic ? 'green' : 'red'" class="ma-2" text-color="white" label>{{ getCheckBonus(item.payedAutomatic) }}</v-chip>

      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom v-if="item.status == 2">
          <template v-slot:activator="{attrs, on}">
            <v-icon small class="mr-2" v-bind="attrs" v-on="on" @click="showReceipt({item: item})">mdi-receipt</v-icon>
          </template>
          <span>Visualizar comprovante</span>
        </v-tooltip>
        
        <v-tooltip bottom>
          <template v-slot:activator="{attrs, on}">
            <v-icon small class="mr-2" v-bind="attrs" v-on="on" @click="viewWithdrawal(item)">mdi-information</v-icon>
          </template>
          <span>Informações do saque</span>
        </v-tooltip>

        <v-tooltip bottom v-if="(item.status == 0 || item.status == 5) && profileAccess()">
          <template v-slot:activator="{attrs, on}">
            <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="edit({withdrawal: item, screen: 'withdrawal'})">mdi-pencil</v-icon>
          </template>
          <span>Editar saque</span>
        </v-tooltip>

        <v-tooltip bottom v-if="(item.typeSend == 3 && item.status == 0) || item.status == 12">
          <template v-slot:activator="{on, attrs}">
            <v-icon small class="mr-2"
              @click="showDialogReorder({ item })"  v-on="on" v-bind="attrs"
              >mdi-robot</v-icon>
          </template>
          <span>Reenviar Ordem</span>
        </v-tooltip>

        <v-tooltip bottom v-if="(item.status == 0) && profileAccess()">
          <template v-slot:activator="{attrs, on}">
            <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="showDeleteDialog(item)">mdi-delete</v-icon>
          </template>
          <span>Excluir saque</span>
        </v-tooltip>

        <v-tooltip bottom 
          v-if="(item.status == 5) && (item.type == 0 || item.type == 4 || item.type == 2) && profileAccess()"
        >
          <template v-slot:activator="{attrs, on}">
            <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="chipsRemoved({withdrawal: item, screen: 'withdrawal'})">mdi-poker-chip</v-icon>
          </template>
          <span>Fichas Retiradas</span>
        </v-tooltip>

        <!--v-tooltip bottom v-if="item.status == 4 && profileAccess()">
          <template v-slot:activator="{attrs, on}">
            <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="stopWithdrawal({withdrawal: item, screen: 'withdrawal'})">mdi-step-backward</v-icon>
          </template>
          <span>Voltar</span>
        </v-tooltip-->

        <v-tooltip bottom 
        v-if="item.status == 13 && (item.typeSend == 3 || item.typeSend == 5) && profileAccess()"
        >
          <template v-slot:activator="{attrs, on}">
            <v-icon color="green" v-bind="attrs" v-on="on" small class="mr-2" @click="approveWithdrawalInternal({withdrawal: item})">mdi-cash-check</v-icon>
          </template>
          <span>Aprovar Saque</span>
        </v-tooltip>

        <v-tooltip bottom 
          v-if="((item.status == 0 || item.status == 5) && (item.type != 0 && item.type != 3 && item.type != 4)) || (item.status == 4 && item.type != 3 && item.type != 6) && profileAccess()"
        >
          <template v-slot:activator="{attrs, on}">
            <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="isPossiblePix(item) ? clickStartWithdrawal({withdrawal: item, screen: 'withdrawal', possible_pix: true}) : clickStartWithdrawal({withdrawal: item, screen: 'withdrawal', possible_pix: false})">mdi-play</v-icon>
          </template>
          <span>Iniciar</span>
        </v-tooltip>

        <v-tooltip bottom v-if="item.status == 1 && profileAccess()">
          <template v-slot:activator="{attrs, on}">
            <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="stopWithdrawal({withdrawal: item, screen: 'withdrawal'})">mdi-pause</v-icon>
          </template>
          <span>Voltar</span>
        </v-tooltip>

        <v-tooltip bottom v-if="(item.status == 6 || item.status == 1 || (item.status == 4 && (item.type == 6 || item.type == 3))) && profilePermissions('finish_withdrawal')">
          <template v-slot:activator="{attrs, on}">
            <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="(item.type == 3 || item.type == 6) ? finishWithdrawalAnnotated({withdrawal: item, screen: 'withdrawal'}) :  finishWithdrawal({withdrawal: item, screen: 'withdrawal'})">mdi-check</v-icon>
          </template>
          <span>Finalizar</span>
        </v-tooltip>

        <v-tooltip bottom v-if="item.status == 6">
          <template v-slot:activator="{attrs, on}">
            <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="checkWithdrawalStatus({ withdrawal_id: item.id })">mdi-shield-search</v-icon>
          </template>
          <span>Consultar saque</span>
        </v-tooltip>
        <v-tooltip bottom v-if="item.status == 6 && checkProfile()" >
          <template v-slot:activator="{attrs, on}">
            <v-icon v-bind="attrs" v-on="on" medium color="success" class="mr-2" @click="showDialogConfirmFinishWithdrawal({item: item})">mdi-cash-check</v-icon>
          </template>
          <span>Saque Realizado</span>
        </v-tooltip>

        <v-tooltip bottom v-if="item.status == 2 && profileAccess()">
          <template v-slot:activator="{attrs, on}">
            <v-icon v-bind="attrs" v-on="on" small  class="mr-2" @click="showReverseDialog({withdrawal: item, screen: 'withdrawal'})">mdi-close</v-icon>
          </template>
          <span>Estornar</span>
        </v-tooltip>

        <v-tooltip bottom v-if="(item.status == 0 || item.status == 1 || item.status == 3 || item.status == 4 || item.status == 5 || item.status == 13) && profileAccess()">
          <template v-slot:activator="{attrs, on}">
            <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="refuseWithdrawalDialog({withdrawal: item, screen: 'withdrawal'})">mdi-close-box-multiple-outline</v-icon>
          </template>
          <span>Recusar</span>
        </v-tooltip>

        <v-tooltip bottom v-if="(item.status == 4) && profileAccess()">
          <template v-slot:activator="{attrs, on}">
            <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="checkPixPayment({withdrawal: item, screen: 'withdrawal'})">mdi-bank-transfer-out</v-icon>
          </template>
          <span>Verificar Pagamento</span>
        </v-tooltip>

        <v-tooltip bottom v-if="item.contact_id != ''">
          <template v-slot:activator="{attrs, on}">
            <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="showContactDialog({withdrawal: item, screen: 'withdrawal'})">mdi-account</v-icon>
          </template>
          <span>Visualizar Contato</span>
        </v-tooltip>

        <v-tooltip bottom v-if=" item.status == 4">
          <template v-slot:activator="{attrs, on}">
            <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="scheduleWithdrawal({withdrawal: item, screen: 'withdrawal'})">mdi-calendar</v-icon>
          </template>
          <span>Agendado</span>
        </v-tooltip>

        <v-tooltip bottom  v-if="item.type_send == 5 && (item.type == 0 || item.type == 4)">
          <template v-slot:activator="{on, attrs}">
            <v-icon small v-on="on" v-bind="attrs" class="mr-2" @click="findTransactionPrint({ item })"> mdi-image-area</v-icon>
          </template>
          <span>Print</span>
        </v-tooltip>

        <v-tooltip bottom  v-if="item.status == 8 && profilePermissions('finish_withdrawal')">
          <template v-slot:activator="{on, attrs}">
            <v-icon small v-on="on" v-bind="attrs" class="mr-2" @click="openDialogUpdate({ item })">mdi-folder-check-outline</v-icon>
          </template>
          <span>Atualizar Saque</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">
            <v-icon small v-on="on" v-bind="attrs" class="mr-2" @click="openHistoryDialog({ item })">mdi-history</v-icon>
          </template>
          <span>Histórico</span>
        </v-tooltip>

      </template>
    </v-data-table>
    
    <v-dialog :value="dialog" @keydown.esc="closeDialog()" @click:outside="closeDialog()" width="1000">
      <withdrawal-form screen="withdrawal" :instances="instances"></withdrawal-form>
    </v-dialog>

    <v-dialog :value="dialogPrint" width="600" @click:outside="showDialogPrint({show: false})">

      <v-card height="350px">
        <v-img :src="print" contain></v-img>
      </v-card>

    </v-dialog>

    <v-dialog :value="dialogUpdate"  @keydown.esc="closeDialogUpdate({show: false})" @click:outsude="closeDialogUpdate({show: false})" width="450">
      <v-card>
        <v-card-title class="headline text-center justify-center">
          Atualizar Saque
        </v-card-title>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12 sm12 md12 v-if="this.mode != 'FINISH'">
              <v-select
                  label="Novo Status*"
                  :items="possible_update_status"
                  v-model="updateStatus"
                  item-text="description"
                  item-value="id"
                  data-vv-name="updateStatus"
                  outlined
              >
              </v-select>
            </v-flex>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDialogUpdate()">Cancelar</v-btn>
          </v-col>
          <v-col>
            <v-btn text @click="updateStatusWithdrawal()">Atualizar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <v-dialog :value="dialogAutomaticWithdrawal"  @keydown.esc="showDialogAutomaticWithdrawal({show: false})" @click:outsude="showDialogAutomaticWithdrawal({show: false})" width="450">
      <v-card>
        <v-card-title class="headline text-center justify-center">
          Iniciar Saque
            <v-progress-circular
              v-if="loading"
              indeterminate
              class="text-right"
              color="primary"
              size="24"
            ></v-progress-circular>
        </v-card-title>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12 sm12 md12 v-if="this.mode != 'FINISH'">
              <v-select
                  label="Banco Pagamento*"
                  :items="banksAllowedWithdrawal"
                  v-model="withdrawalAutomatic.bank_payment"
                  :error-messages="errors.collect('bank_payment')"
                  :item-text="getTextBank"
                  item-value="id"
                  data-vv-name="bank_payment"
                  outlined
                  :readOnly="forceBank"
              >
              </v-select>
            </v-flex>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-col>
            <v-btn text @click="showDialogAutomaticWithdrawal({show: false})">Cancelar</v-btn>
          </v-col>
          <!-- <v-col>
            <v-btn text @click="startWithdrawal({withdrawal: withdrawalAutomatic, screen: 'withdrawal'})">Manual</v-btn>
          </v-col> -->
          <v-col class="text-right" v-if="withdrawalAutomatic.value < 20000 || (this.userData.role == 80 || this.userData.role == 93 || this.userData.role == 98 || this.userData.role == 99)">
            <v-btn :disabled="block_automatic" text type="submit" right @click="automaticPix({withdrawal: withdrawalAutomatic, screen: 'withdrawal'})">Automático</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <v-dialog scrollable :value="dialogBlacklistWithdrawals" @keydown.esc="showDialogBlacklistWithdrawals({show: false})" @click:outside="showDialogBlacklistWithdrawals({show: false})" width="1000">
      <v-card>
        <v-card-title class="headline text-center justify-center">
          Blacklist / Alertas e Bloqueios
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 500px;">
          <v-layout wrap>
            <v-flex xs12 sm12 md12 v-if="blocked_itens.lenght != 1">
              <v-alert
                dense
                border="left"
                type="error"
              >
                <strong>BLOQUEIOS</strong>
              </v-alert>
              <v-data-table
                :headers="headers_blocked_itens"
                :loading="loading"
                :items="blocked_itens"
                :hide-default-footer="true"
                class="elevation-10 table-margin"
                no-data-text="Nenhum ou alerta encontrado"
              >
                <template v-slot:[`item.blockedType`]="{ item }">
                  <span>{{ getBlockedTypeName(item.blockedType) }}</span>
                </template>
              </v-data-table>
            </v-flex>

            <v-flex xs12 sm12 md12>
              <v-alert
                dense
                border="left"
                type="warning"
              >
                <strong>ALERTAS</strong>
              </v-alert>
            </v-flex>

            <v-flex xs12 sm12 md12 v-if="this.alert_itens.idAppAndPixKey == true">
              <template>
                <v-banner
                  single-line
                  icon="mdi-alert-box-outline"
                >
                  <v-icon
                    slot="icon"
                    color="warning"
                    size="36"
                  >
                    mdi-wifi-strength-alert-outline
                  </v-icon>
                  Alerta: saque realizado menos que três vezes deste ID para esta Chave Pix...
                </v-banner>
              </template>
            </v-flex>
            <v-flex xs12 sm12 md12 v-if="this.alert_itens.inscriptionAndPixKey == true">
              <template>
                <v-banner
                  single-line
                  icon="mdi-alert-box-outline"
                >
                  <v-icon
                    slot="icon"
                    color="warning"
                    size="36"
                  >
                    mdi-wifi-strength-alert-outline
                  </v-icon>
                  Alerta: depósito realizado menos que três vezes...
                </v-banner>
              </template>
            </v-flex>
            <v-flex xs12 sm12 md12 v-if="this.alert_itens.checkIdWithError == true">
              <template>
                <v-banner
                  single-line
                  icon="mdi-alert-box-outline"
                >
                  <v-icon
                    slot="icon"
                    color="warning"
                    size="36"
                  >
                    mdi-wifi-strength-alert-outline
                  </v-icon>
                  Alerta: Verificação de ID com erro...
                </v-banner>
              </template>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="text-center">
          <v-col>
            <p>Foram encontrados bloqueios e/ou alertas para este saque, deseja continuar mesmo assim?</p>
          </v-col>
          <v-col>
            <v-btn color="error" @click="showDialogBlacklistWithdrawals({show: false})">Cancelar</v-btn>
          </v-col>
          <v-col>
            <v-btn color="success" @click="startWithdrawalWithBlocksAlerts()" v-if="checkProfilePermissions()">Continuar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <v-dialog :value="dialogFilter" @keydown.esc="showFilter({show: false})" @click:outside="showFilter({show: false})" width="600">
      <withdrawal-filter></withdrawal-filter>
    </v-dialog>

    <v-dialog
      v-model="dialogReceipt"
    >
      <v-card height="90vh">
        <v-img max-height="100%" :src="receipt_show" contain></v-img>
      </v-card>
    </v-dialog>

    <v-dialog :value="dialogDelete" @keydown.esc="closeDeleteDialog" @click:outside="closeDeleteDialog" @keydown.enter="deleteItem" width="300">
      <v-card>
        <v-card-title class="headline">Confirmar a exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn
              text
              @click="closeDeleteDialog()"
            >Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              @click="deleteItem"
            >Confirmar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog :value="dialogReverse" @keydown.esc="closeReverseDialog" persistent width="400">
      <v-card>
        <v-card-title class="headline text-center justify-center">Deseja confirmar o extorno?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn
              text
              @click="closeReverseDialog()"
            >Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              @click="reverseWithdrawal"
            >Confirmar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog :value="dialogRefuseWithdrawal" @keydown.esc="showDialogRefuseWithdrawal({show: false})" @click:outside="showDialogRefuseWithdrawal({show: false})" width="450">
      <v-card>
        <v-card-title class="headline text-center justify-center">
          Recusar Saque
            <v-progress-circular
              v-if="loading"
              indeterminate
              class="text-right"
              color="primary"
              size="24"
            ></v-progress-circular>
        </v-card-title>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12 sm12 md12>
              <v-textarea
                  auto-grow
                  required
                  label="Mensagem *"
                  v-model="message_refused"
                  :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                  :error-messages="errors.collect('message_refused')"
                  v-validate="'required:Mensagem,message_refused'"
                  data-vv-name="message_refused"
                  outlined
                  rows="2"
              ></v-textarea>
            </v-flex>
            <v-flex>
              <v-checkbox
                  v-if="(withdrawalRefused.status == 4 || withdrawalRefused.status == 1 || withdrawalRefused.status == 5) && withdrawalRefused.type != 5 && (withdrawalRefused.typeSend == 3 || withdrawalRefused.typeSend == 5)"
                  label="Devolver Fichas"
                  v-model="refund"
                  :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                  outlined
              ></v-checkbox>
            </v-flex>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-col>
            <v-btn text @click="showDialogRefuseWithdrawal({show: false})">Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text :disabled="block_refuse" :readonly="block_refuse" @click="refuseWithdrawal({screen: 'withdrawal'})">Recusar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog :value="dialogHistory" width="1280" @keydown.esc="showDialogHistory({show: false})" @click:outside="showDialogHistory({show: false})">
      <v-card>
        <v-card-title>Histórico de Saques</v-card-title>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header
              >Exibir Dados do Saque em Analise</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-layout wrap>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="CPF/CNPJ"
                    :readonly="true"
                    outlined
                    :value="withdrawalHistory.inscription"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Nome"
                    :readonly="true"
                    outlined
                    :value="withdrawalHistory.name"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Chave Pix"
                    :readonly="true"
                    outlined
                    :value="withdrawalHistory.pixKey"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-data-table
          :headers="historyHeaders"
          :items="historyItems"
          :item-class= "getClassColor"
          no-data-text="Nenhum saque encontrado"
          >
          <template v-slot:[`item.createdAt`]="{ item }">
            <span>{{ getDate(item.createdAt) }}</span>
          </template>
          <template v-slot:[`item.value`]="{ item }">
            <span>{{ getCurrency(item.value) }}</span>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <span>{{ getStatus(item.status) }}</span>
          </template>
          <template v-slot:[`item.compatible`]="{ item }">
            <v-chip text-color="white" :color="getColorCompatible(getCompatible(item))">
              <span>{{ getCompatible(item) }}</span>
            </v-chip>
          </template>
          <template v-slot:[`item.value`]="{ item }">
            <span>{{ (item.value) ? item.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 0 }}</span>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>

    <v-dialog :value="dialogConfirmFinishWithdrawal" @keydown.esc="closeDialogConfirmFinishWithdrawal" @click:outside="closeDialogConfirmFinishWithdrawal" width="760">
      <v-card class="" >
        <v-card-title class="text-h4 text-center mb-5">Tem certeza que esse saque foi efetuado ?

        </v-card-title>
        <div class="text-center">
        <span class="text-h6 
 pa-2 ma-2 " > Favor confirmar no extrato do <span class="font-weight-black"> {{ getBankName(editedItem.bank_payment_name) }} </span> se esse saque foi concluído com sucesso</span >
        </div>
    
        <v-card-actions>
          <v-col>
            <v-btn button color="error" @click="closeDialogConfirmFinishWithdrawal()">Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn button type="submit" color="success" right @click="finishWithdrawalInProcess">Confirmar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog :value="dialogReorder" @keydown.esc="closeReorderDialog" @click:outside="closeReorderDialog" width="500">
      <v-card>
        <v-card-title class="headline">Confirmar o reenvio de ordem ao robô?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closeReorderDialog()">Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="reorder">Confirmar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogContact"
      max-width="500"
    >
      <v-card>
        <contact-card :contact_id="contact_show.phone" :contact_name="contact_show.name"></contact-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
  import WithdrawalForm from './WithdrawalForm'
  import WithdrawalFilter from './WithdrawalFilter'
  import ContactCard from '../../attendance/Chat/components/ContactCard'
  import { mapFields } from 'vuex-map-fields'
  // import JsonExcel from 'vue-json-excel'
  import valida_cpf_cnpj from '../../../util/CpfCnpj'
  import { utils, writeFile } from "xlsx";

  export default {
    name: 'Withdrawls',
    computed: {
      ...mapState("bank", { banks: "items" }),
      ...mapState('login', ['userData']),
      ...mapState('profile', ['profile']),
      ...mapState('clubs', ['clubs']),
      ...mapState('withdrawal', ['withdrawalTotals', 'contact_show', 'forceBank', "avg_time", 'dialog', 'dialogDelete', 'item','instances', 'errors', 'loading', 'mode', 'notificationType', 'notificationMessage', 'teste', 'editedItem',
        'receipt_show', 'rowsPerPage', 'withdrawalHistory', 'withdrawalAutomatic', 'withdrawalRefused', 'totalItems', 'totalValue', 'items', 'historyItems', 'filtered', 'print', 'dialogPrint', 'dialogReorder', 'dialogUpdate', 'dialogConfirmFinishWithdrawal', ]),
      ...mapFields('withdrawal', [
          'alert_itens',
          'block_automatic',
          'block_refuse',
          'blocked_itens',
          'overlay',
          'withdrawalAutomatic.bank_payment',
          'withdrawalRefused.message_refused',
          'withdrawalRefused.refund',
          'withdrawalUpdate.status',
          'bankAutomatic',
          'dialogContact',
          'dialogReceipt',
          'pagination',
          'dialogFilter',
          'dialogReverse',
          'dialogAutomaticWithdrawal',
          'dialogBlacklistWithdrawals',
          'dialogRefuseWithdrawal',
          'dialogHistory',
          'profile_permissions',
          'showNotification',
          'updateStatus'
          // ...
        ]),
      ...mapState('bank_sub_accounts', {banks_payment: 'items'}),
      banksAllowedWithdrawal() {
        if(this.forceBank) {
          console.log("Forçando banco: ", this.forceBank, this.bank_payment)
          return this.banks_payment.filter((b) => b.id == this.bank_payment)
        } else {
          return this.banks_payment.filter((b) => b.accountPaymentWithdrawals == 1)
        }
      },
      banksPix() {
        return this.banks.filter((b) => b.make_withdrawals == 1)
      }
    },
    created() {
      this.watchWithdrawals()
      this.getItems()
      this.getBanks()
      this.getUsers({ noPages: true})
      this.setScreen({screen: 'withdrawal'})
      this.getSlots({ resetPage: false, noPages: true, onlyIfNotSearched: true});
      this.getClubs({ resetPage: false, noPages: true, onlyActive: true});
      this.getBanksPayment({ resetPage: false, noPages: true, onlyIfNotSearched: false, inactive: false });
    },
    components: {
      "withdrawal-form": WithdrawalForm,
      "withdrawal-filter": WithdrawalFilter,
      "contact-card": ContactCard,
    },
    methods: {
      ...mapGetters('withdrawal', ['checkProfileAcessWithdrawalLeader']),
      ...mapMutations('withdrawal', ['add', 'closeReverseDialog', 'edit', 'viewItem', 'finishWithdrawal', 'showOverlay', 'setScreen', 'showFilter', 'showDialogAutomaticWithdrawal', 'showDialogBlacklistWithdrawals', 'showDialogRefuseWithdrawal', 'showDialogPrint', 'showDialogHistory']),
      ...mapActions("bank", { getBanks: "getItems" }),
      ...mapActions("bank_sub_accounts", { getBanksPayment: "getItems" }),
      ...mapActions("slots", [
        "getSlots",
      ]),
      ...mapActions("withdrawal", ['restart24hService']),
      ...mapActions("clubs", ["getClubs"]),
      ...mapActions("users", ["getUsers"]),
      ...mapActions('withdrawal', ['checkPixPayment', 'finishWithdrawalInProcess', 'getPixesByWithdrawal', 'clickStartWithdrawal','approveWithdrawalInternal', 'closeDialog', 'closeDeleteDialog', 'closeReorderDialog', 'chipsRemoved', 'deleteItem', 'getItems', 'reorder', 'getItemsNoPage', 'getProfilePermissions', 'showReceipt', 'showDeleteDialog', 'startWithdrawal', 'showDialogReorder', 'showDialogConfirmFinishWithdrawal', 'closeDialogConfirmFinishWithdrawal',
                                    'finishWithdrawalAnnotated', 'stopWithdrawal', 'startWithdrawalWithBlocksAlerts', 'startWithdrawalPix', 'payWithdrawalAutomatic', 'scheduleWithdrawal', 'showContactDialog', 'showReverseDialog', 'refuseWithdrawalDialog','reverseWithdrawal', 'refuseWithdrawal', 'findTransactionPrint', 'openHistoryDialog', 'checkWithdrawalStatus', 'closeDialogUpdate', 'openDialogUpdate', 'updateStatusWithdrawal']),
      formatDateXlsx(date) {
        if(date) {
          var dateFormatted = date.substr(6, 4) + "-" + date.substr(3, 2) + "-" + date.substr(0,2) + date.substr(10) + ":00"
          return dateFormatted
        } else {
          return date
        }
      },
      async downloadXlsx(){
        this.showOverlay({ show: true })
        try {
          let itemsNoPage = await this.getItemsNoPage();
          if(itemsNoPage) {
            const wb = utils.book_new();
            let type_sends = ["Pendente", "Solicitado", "Realizado", "Manual", "Erro Bot", "Realizado - PB"]
            let possible_status = ["Pendente", "Cancelado", "Concluido", "Estonardo", "Aguardando Pagamento", "Agendado", "Em Processamento", "Recusado", "Erro Banco", "Aguardando Devolução", "Erro Devolução"]
            let items = itemsNoPage.map(row => ({
              "ID": row.id,
              "Nome": row.name,
              "CPF/CNPJ": row.inscription,
              "Criado em": row.createdAt,
              "Finalizado em": row.finishAt,
              "Valor": row.value, 
              "Id Suprema": row.idApp,
              "Descrição": row.description,
              "Clube": row.clubName,
              "Retirada": type_sends[row.typeSend],
              "Tipo": this.getTypeDescription(row.type),
              "Status": possible_status[row.status],
              "Tempo Execução": row.finishAt ? this.getTimeWithdrawal(row.createdAt, row.finishAt) : '',
              "Tempo Execução(s)": row.finishAt ? this.getTimeWithdrawalSeconds(row.createdAt, row.finishAt, true) : ''
            }))
            const ws = utils.json_to_sheet(items);
            
            utils.book_append_sheet(wb, ws, "Saques");
            await writeFile(wb, this.getSheetName());2
           
          }
        } catch (error) {
          this.showOverlay({ show: false })
        } finally {
          this.showOverlay({ show: false })
        }

        
      },
      getBlockedTypeName(param) {
        return this.possible_blocked_types[param];
      },
      getBankName(name){
        if(name) {
          return name
        } else {
          return "BANCO"
        }
      },
      getDate(date) {
        if (date) {
          var d = new Date(date);
          return d.toLocaleDateString() + " " + d.toLocaleTimeString();
        }
        return "";
      },
      getCompatible(item) {
        if(item.inscription == this.withdrawalHistory.inscription && item.inscription == this.withdrawalHistory.inscription) {
          return "Pix e CPF"
        } else if(item.inscription == this.withdrawalHistory.inscription) {
          return "CPF"
        } else if(item.key == this.withdrawalHistory.key) {
          return "Pix"
        } else {
          return "Incompatível"
        }
      },
      getColorCompatible(compatible) {
        if(compatible == "Pix e CPF") {
          return "green"
        } else if(compatible == "CPF") {
          return "purple"
        } else if(compatible == "Pix") {
          return "purple"
        } else {
          return "red"
        }
      },
      getAccountType(type) {  
        switch(type) {
          case 0: 
            return 'Corrente'
          case 1: 
            return 'Poupança'
          case 2:
            return 'Pix'
        }
      },
      getCurrency(value) {
        value = parseFloat(value)
        return value ? value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "-------"
      },
      getColorByTypeSend(type_send) {
        if(type_send == 0) {
          return 'gray'
        }
        else if(type_send == 1) {
          return 'cyan'
        } else if(type_send == 5) {
          return 'green'
        } else if(type_send == 2) {
          return 'orange'
        } else if(type_send == 3 || type_send == 4) {
          return 'red'
        }
      },
      getColorByStatus(status) {
        if(status == 0 || status == 12) {
          return 'red'
        } else if(status == 1) {
          return 'light-green'
        }
        else if(status == 8 || status == 3 || status == 11) {
          return 'black'
        } else if(status == 2) {
          return 'green'
        } else if(status == 6) {
          return 'blue'
        } else if(status == 7) {
          return '#8B5742'
        }  else if(status == 4) {
          return 'orange'
        } else if(status == 5) {
          return 'pink'
        } else if(status == 10) {
          return 'brown'
        } else if(status == 9) {
          return '#8B008B'
        }else if(status == 13) {
          return '#00695C'
        }
      },
      getColorByConfidenceRate(rate) {
        // if(rate >= 0 && rate <= 30) {
        //   return 'red'
        // }
        // else if(rate > 30 && rate <= 50) {
        //   return 'orange'
        // } else if(rate > 50 && rate <= 80) {
        //   return 'yellow'
        // } else if(rate > 80 && rate <= 100) {
        //   return 'green'
        // }

        if (rate){
          return 'green'
        }
      },
      isPossiblePix(w) {
        console.log(w.pixKey != "", (w.name != "" && w.inscription != "" && w.account != "" && w.agency != ""))
        if(w.pixKey != "") {
          return true
        } else if(w.name != "" && w.inscription != "" && w.account != "" && w.agency != "") {
          console.log(valida_cpf_cnpj)
          return valida_cpf_cnpj(w.inscription)
        }
        console.log('deu ruim')
        return false
      },
      automaticPix(data) {
        event.target.disabled = true
        this.payWithdrawalAutomatic(data).then(() => { event.target.disabled = false });
      },
      getTextBank(item) {
        return item.nickname
      },
      getClassColor(item) {
        if(item.status == 2) {
          return 'row-green'
        } else if(item.status == 9) {
          return 'row-rot-violet'
        } else if(item.status == 0) {
          if(item.type_send == 1) {
            return 'row-teal'
          } else if(item.type_send == 4) {
            return 'row-refused'
          } else {
            return 'row-red'
          }
        } else if(item.status == 3) {
          return 'row-grey'
        } else if(item.status == 4) {
          return 'row-orange'
        } else if(item.status == 5) {
          return 'row-pink'
        } else if(item.status == 6 || item.type_send == 1) {
          return 'row-blue'
        } else if(item.status == 7) {
          return 'row-refused'
        } else if(item.status == 1 && item.contact_id != null && (item.id_instance == 24 || item.id_instance == 12)) {
          return 'row-purple'
        } else if(item.status == 10) {
          return 'row-refused'
        }
        else if (item.status == 13) {
          return 'row-teal'
        }
      },
      isMobile() {
        if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
          return true;
        }
        return false
      },
      getStatus(type) {  
        if(typeof type == 'string') {
          return type
        } else {
          switch(type) {
            case 0: 
              return 'Pendente'
            case 1: 
              return 'Em andamento'
            case 2:
              return 'Concluido'
            case 3: 
              return 'Estornado'
            case 4: 
              return 'Aguardando Pagamento'
            case 5: 
              return 'Agendado'
            case 6:
              return 'Processamento'
            case 7:
              return 'Recusado'
            case 8:
              return 'Erro Banco'
            case 9: 
              return 'Pendente de Aprovação'
            case 10: 
              return 'Reprovado'
            case 11:
              return 'Aguardando Devolução'
            case 12:
              return 'Erro Devolução'
            case 13:
              return 'Pendente de Aprovação Interna'
          }
        }
 
      },
      getSendedStatus(type) {  
        switch(type) {
          case 0: 
            return '-----'
          case 1: 
            return 'Enviado'
          case 2:
            return 'Recebido'
        }
      },
      getSendType(type) {
        switch (type) {
          case 0:
            return "Pendente";
          case 1:
            return "Solicitado";
          case 2:
            return "Realizado";
          case 3:
            return "Manual";
          case 4: 
            return "Erro Bot";
          case 5:
            return "Realizado - PB"
        }
      },
      getTimeWithdrawal(created_at, finish_at) {
        if(!created_at || !finish_at) return ''
        let date_start = new Date(created_at)
        let date_finish = new Date(finish_at)
        let diff = date_finish.getTime() - date_start.getTime()
        let hours = Math.floor(diff / 1000 / 60 / 60)
        let minutes = Math.floor(diff / 1000 / 60) - (hours * 60)
        let seconds = Math.floor(diff / 1000) - (hours * 60 * 60) - (minutes * 60)
        if(hours < 10) hours = '0' + hours
        if(minutes < 10) minutes = '0' + minutes
        if(seconds < 10) seconds = '0' + seconds
        return hours + ':' + minutes + ':' + seconds
      },
      getTimeWithdrawalSeconds(created_at, finish_at) {
        if(!created_at || !finish_at) return ''
        let date_start = new Date(created_at)
        let date_finish = new Date(finish_at)
        let diff = date_finish.getTime() - date_start.getTime()
        let seconds = Math.floor(diff / 1000)
        return seconds
      },
      getTypeDescription(type) {
        let types = ['Fichas', 'Rakeback', 'Saldo Positivo', "Transferência", 'Ordem Maleta', 'Devolução', 'Abate de Anotados', 'Retirada Ganhos e Perdas']
        return types[type]
      },
      getIsEmpty(value) {  
        if (value === "") {
          return "-----"
        }

        return value 
      },
      getCheckBonus(value) {  
        if (value == "" || !value ) {
          return "Não"
        }

        return 'Sim' 
      },
      getSheetName() {
        var date = new Date()
        var name = 'saques_' + date.toLocaleDateString() + " " + date.toLocaleTimeString()
        return name+'.xlsx'
      },
      checkProfilePermissions() {
        if (this.profile_permissions.make_blocked_withdrawal != 0 && this.blocked_itens.length != 0) {
          return true
        } else if (this.profile_permissions.make_alert_withdrawal != 0 && this.alert_itens.alert_id_pppoker_and_pix_key != 0) {
          return true
        } else if (this.profile_permissions.make_alert_withdrawal != 0 && this.alert_itens.alert_deposits_by_client_key_and_id_pppoker != 0) {
          return true
        } else {
          return false
        }
      },
      profileAccess() {
        if(this.userData.role == 0 || this.userData.role == 1 || this.userData.role == 70) {
          return false
        } else {
          return true
        }
      },
      checkProfile(){
        if(this.userData.role >= 80){
          return true
        } else {
          return false
        }
      },
      profilePermissions(param) {
        return this.profile[param] == 1
      },
      saveWithdrawal() {
        this.$validator.validateAll().then(valid => {
          if(valid) {
            this.value = parseFloat(this.value)
            this.balance_pppoker = parseFloat(this.balance_pppoker)
            this.save()
          }
        });
      },
      watchWithdrawals() {
        if(this.mode == 'LIST') {
          this.getItems()
        }
        if(this.$router) {
          if(this.$router.history.current.name == "withdrawal") {
            setTimeout(() => {
              this.watchWithdrawals()
            }, 60000)
          }
        }
      },
      viewWithdrawal(item) {
        this.viewItem(item)
        this.getPixesByWithdrawal({withdrawal: item})
      }
    },
    data: () => ({
      expanded: [],
      possible_blocked_types: ["ID", "CPF", "CNPJ", "Chave PIX" ],
      historyHeaders: [
        { text: "ID", value: "id", sortable: false },
        { text: "Data", value: "createdAt", sortable: false },
        { text: "Clube", value: "club_label", sortable: false },
        { text: "Nome", value: "name", sortable: false },
        { text: "Status", value: "status", sortable: false },
        { text: "Chave Pix", value: "pixKey", sortable: false },
        { text: "Valor", value: "value", sortable: false },
        { text: "CPF/CNPJ", value: "inscription", sortable: false },
        { text: "Contato", value: "phone", sortable: false },
        { text: "Compatiblidade", value: "compatible", sortable: false }
      ],
      headers: [
        { text: "ID", value: "id", sortable: false }, 
        { text: "App", value: "idClient", sortable: false, align: 'center' },
        // { text: "CPF/CNPJ", value: "inscription", sortable: false },
        {
          text: "Nome",
          align: "left",
          value: "name",
          sortable: true
        },
        // { text: "Banco", value: "bank_name", sortable: true },
        { text: 'Valor Solicitado', value: "requestedValue", sortable: true },
        { text: "Valor Aprovado", value: "value", sortable: true },
        { text: "Clube", value: "clubName", sortable: true },
        { text: "ID App", value: "idApp", sortable: true},
        { text: "Tipo", value: "type", sortable: true },
        { text: "Status", value: "status", sortable: true },
        { text: "Retirada", value: "typeSend", sortable: true },
        // { text: "Saldo", value: "balance_pppoker", sortable: false },
        { text: "Recebido", value: "createdAt", sortable: true },
        { text: "Aprovado Em", value: "approvalAt", sortable: true },
        { text: "T. Execução", value: "finishAt", sortable: true },
        { text: "P. Aprovação", value: "passedForApproval", sortable: true},
        { text: "Verificar Bônus", value: "checkBonus", sortable: true },
        { text: "CPF/CNPJ", value: "inscription", sortable: false },
        { text: "Automático", value: "payedAutomatic", sortable: false },
        // { text: "Confiança %", value: "confidence_rate", sortable: false },
        { text: '', value: 'data-table-expand', sortable: false },
        { text: "Ações",  value: "actions", sortable: false, align: "center", width: '10%' }
      ],
      headers_blocked_itens: [
        { text: "Data", align: "center", value: "dateCreated", sortable: false },
        { text: "Tipo de Bloqueio", align: "center", value: "blockedType", sortable: true },
        { text: "ID Blacklist", align: "center", value: "idBlacklist", sortable: true },
      ],
      account_types: [
        {account_type: 0, description: 'Corrente'},
        {account_type: 1, description: 'Poupança'},
        {account_type: 2, description: 'Pix'}
      ],
      send_types: [
        {send_types: 0, description: 'Solicitado'},
        {send_types: 1, description: 'Realizado'},
        {send_types: 2, description: 'Manual'}
      ],
      possible_status: [
        {status: 0, description: 'Pendente'},
        {status: 1, description: 'Cancelado'},
        {status: 2, description: 'Concluido'},
        {status: 3, description: 'Estornado'},
        {status: 4, description: 'Aguardando Pagamento'},
        {status: 9, description: 'Pendente de Aprovação'},
        {status: 10, description: 'Aguardando Devolução'},
        {status: 11, description: 'Erro Devolução'}
      ],
      possible_update_status: [
        {id: 2, description: 'Concluido'},
        {id: 4, description: 'Aguardando Pagamento'}
      ],
      rules: {
        required: value => !!value || "Required.",
        counter: value => value.length <= 20 || "Max 20 characters"
      },
      valid: false
    }),
    watch: {
      pagination: {
        handler () {
          this.getItems()
        },
      },
    },
  } 

</script>

<style>
  @import './css/withdrawal.css';
</style>