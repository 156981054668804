<template>
    <div>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <v-snackbar
            :color="notificationType"
            top
            center
            :timeout="3000"
            v-model="showNotification"
            >{{ notificationMessage }}</v-snackbar
        >
        <v-toolbar flat class="elevation-5">
            <div>
                <v-toolbar-title>Depósitos</v-toolbar-title>
            </div>
            <v-spacer></v-spacer>
            <div v-if="checkProfileAccessManager()">
                <b
                    >Valor Total:
                    {{
                        total_value
                            ? total_value.toLocaleString('pt-BR', {
                                  style: 'currency',
                                  currency: 'BRL',
                              })
                            : 0
                    }}</b
                >
            </div>
            <v-spacer></v-spacer>
            <div>
                <b>Tempo Médio: {{ avg_time }}</b>
            </div>
            <v-spacer></v-spacer>
            <div class="button-toolbar" v-if="!loadingSearchPix">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="black"
                            dark
                            :disabled="loadingSearchPix"
                            :loading="loadingSearchPix"
                            class="ma-2"
                            @click="showDialogSearchPix({ show: true })"
                            small
                            v-on="on"
                            v-bind="attrs"
                        >
                            <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                    </template>
                    <span>Buscar Pix</span>
                </v-tooltip>

                <v-tooltip bottom v-if="filtered">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="black"
                            dark
                            class="ma-2"
                            @click="downloadReceipts()"
                            small
                            v-on="on"
                            v-bind="attrs"
                        >
                            <v-icon>mdi-download</v-icon>
                        </v-btn>
                    </template>
                    <span>Baixar Comprovantes</span>
                </v-tooltip>
                <!-- <download-excel
          :before-generate="generateSheet"
          :before-finish="finishSheet"
          :name="getSheetName()"
          :fetch="getItemsNoPage"
          :fields="sheet_fields"
          class="excel"
          worksheet="Deposito"
        >
          <v-tooltip bottom >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="black"
                dark
                class="ma-2"
                small
                v-on="on"
                v-bind="attrs"
              >
                <v-icon>mdi-file-excel</v-icon>
              </v-btn>
            </template>
            <span>Baixar xls</span>
          </v-tooltip>
        </download-excel> -->
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="black"
                            dark
                            class="ma-2"
                            @click="downloadXlsx({ show: true })"
                            v-bind="attrs"
                            v-on="on"
                            small
                        >
                            <v-icon>mdi-file-excel</v-icon>
                        </v-btn>
                    </template>
                    <span>Gerar Planilha</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="black"
                            dark
                            class="ma-2"
                            @click="showFilter({ show: true })"
                            small
                            v-on="on"
                            v-bind="attrs"
                        >
                            <v-icon :color="filtered ? 'grey' : 'white'"
                                >mdi-filter</v-icon
                            >
                        </v-btn>
                    </template>
                    <span>Filtro</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="black"
                            dark
                            class="ma-2"
                            @click="getItems"
                            small
                            v-on="on"
                            v-bind="attrs"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Recarregar</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="black"
                            dark
                            class="ma-2"
                            @click="add"
                            small
                            v-on="on"
                            v-bind="attrs"
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>Adicionar Depósito</span>
                </v-tooltip>
            </div>
        </v-toolbar>

        <v-data-table
            :headers="headers"
            :loading="loading"
            :items="items"
            :options.sync="pagination"
            :server-items-length="totalItems"
            :item-class="getClassColor"
            no-data-text="Nenhuma depósito encontrado"
            class="elevation-10 table-margin"
            show-expand
            :footer-props="{
                'items-per-page-options': [10, 20, 50, 100],
                'items-per-page-text': 'Itens por página',
                'show-first-last-page': true,
                'page-text': `Página ${pagination.page} de ${
                    parseInt(totalItems / pagination.itemsPerPage) + 1
                }`,
            }"
            :expanded.sync="expanded"
        >
            <template
                v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }"
            >
                <v-icon
                    @click="expand(true)"
                    v-if="item.observation && !isExpanded"
                    >mdi-chevron-down</v-icon
                >
                <v-icon
                    @click="expand(false)"
                    v-if="item.observation && isExpanded"
                    >mdi-chevron-up</v-icon
                >
            </template>
            <template v-slot:expanded-item="{ item }">
                <td>{{ item.observation }}</td>
            </template>
            <template v-slot:[`item.date`]="{ item }">
                <span>{{ getDate(item.date) }}</span>
            </template>
            <template v-slot:[`item.date_created`]="{ item }">
                <span>{{ getDate(item.date_created) }}</span>
            </template>
            <template v-slot:[`item.finish_at`]="{ item }">
                <span>{{ getDate(item.finish_at) }}</span>
            </template>
            <template v-slot:[`item.value`]="{ item }">
                <span>{{
                    item.value.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    })
                }}</span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
                <span>{{ getStatus(item.status) }}</span>
            </template>
            <template v-slot:[`item.payment_type`]="{ item }">
                <span>{{ getPaymentType(item.payment_type) }}</span>
            </template>
            <template v-slot:[`item.type`]="{ item }">
                <span>{{
                    item.id_sx ? getType(item.type) + '-SX' : getType(item.type)
                }}</span>
            </template>
            <template v-slot:[`item.validation_state`]="{ item }">
                <v-chip
                    :color="getColorValidation(item.validation_state)"
                    class="ma-2"
                    :text-color="item.validation_state != 0 ? 'white' : 'black'"
                    label
                    >{{ getValidationState(item.validation_state) }}</v-chip
                >
            </template>
            <template v-slot:[`item.sended`]="{ item }">
                <span>{{ getSendedStatus(item.sended) }}</span>
            </template>
            <template v-slot:[`item.type_send`]="{ item }">
                <v-chip
                    :color="getColorByTypeSend(item.type_send)"
                    class="ma-2"
                    :text-color="item.type_send != 0 ? 'white' : 'black'"
                    label
                    >{{ getSendType(item.type_send) }}</v-chip
                >
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom v-if="item.has_receipt == 1">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            v-on="on"
                            v-bind="attrs"
                            class="mr-2"
                            @click="showReceipt({ item: item })"
                            >mdi-receipt</v-icon
                        >
                    </template>
                    <span>Comprovante</span>
                </v-tooltip>
                <v-tooltip
                    bottom
                    v-if="
                        item.has_receipt == 0 &&
                        profilePermissions('link_receipt')
                    "
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            v-on="on"
                            color="black"
                            v-bind="attrs"
                            class="mr-2"
                            @click="showDialogAddReceipt({ item: item })"
                            >mdi-receipt-text-plus-outline</v-icon
                        >
                    </template>
                    <span>Vincular Comprovante</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            class="mr-2"
                            v-on="on"
                            v-bind="attrs"
                            @click="viewItem({ item: item, screen: 'deposit' })"
                            >mdi-information</v-icon
                        >
                    </template>
                    <span>Informações</span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.status == 0 || item.status == 5">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            v-on="on"
                            v-bind="attrs"
                            class="mr-2"
                            @click="
                                startDeposit({
                                    deposit: item,
                                    screen: 'deposit',
                                })
                            "
                            >mdi-play</v-icon
                        >
                    </template>
                    <span>Iniciar depósito</span>
                </v-tooltip>

                <v-tooltip bottom v-if="item.status == 1">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            v-on="on"
                            v-bind="attrs"
                            class="mr-2"
                            @click="
                                stopDeposit({
                                    deposit: item,
                                    screen: 'deposit',
                                })
                            "
                            >mdi-pause</v-icon
                        >
                    </template>
                    <span>Pausar depósito</span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.status == 1">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            v-on="on"
                            v-bind="attrs"
                            class="mr-2"
                            @click="
                                validateDeposit({
                                    deposit: item,
                                    screen: 'deposit',
                                })
                            "
                            >mdi-check</v-icon
                        >
                    </template>
                    <span>Validar Depósito</span>
                </v-tooltip>

                <v-tooltip bottom v-if="item.status == 6">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            v-on="on"
                            v-bind="attrs"
                            class="mr-2"
                            @click="
                                finishDeposit({
                                    deposit: item,
                                    screen: 'deposit',
                                })
                            "
                            >mdi-check</v-icon
                        >
                    </template>
                    <span>Finalizar Depósito</span>
                </v-tooltip>

                <v-tooltip
                    bottom
                    v-if="
                        item.status == 0 ||
                        item.status == 5 ||
                        item.type_send == 4
                    "
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            class="mr-2"
                            @click="edit({ deposit: item })"
                            v-on="on"
                            v-bind="attrs"
                            >mdi-pencil</v-icon
                        >
                    </template>
                    <span>Editar</span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.type_send == 3 || item.type_send == 4">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            class="mr-2"
                            @click="showDialogReorder({ deposit: item })"
                            v-on="on"
                            v-bind="attrs"
                            >mdi-robot</v-icon
                        >
                    </template>
                    <span>Reenviar Ordem</span>
                </v-tooltip>
                <v-tooltip
                    bottom
                    v-if="
                        item.status == 0 ||
                        item.status == 5 ||
                        item.status == 7 ||
                        (item.status == 6 && [3, 4].includes(item.type_send))
                    "
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            class="mr-2"
                            @click="showDeleteDialog(item)"
                            v-on="on"
                            v-bind="attrs"
                            >mdi-delete</v-icon
                        >
                    </template>
                    <span>Apagar</span>
                </v-tooltip>
                <v-tooltip
                    bottom
                    v-if="
                        item.status != 2 &&
                        item.status != 3 &&
                        item.status != 4 &&
                        item.id_sx
                    "
                >
                    <template v-slot:activator="{ attrs, on }">
                        <v-icon
                            v-bind="attrs"
                            v-on="on"
                            small
                            class="mr-2"
                            @click="
                                refuseDepositDialog({
                                    deposit: item,
                                    screen: 'deposit',
                                })
                            "
                            >mdi-close-box-multiple-outline</v-icon
                        >
                    </template>
                    <span>Recusar</span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.contact_id != ''">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            class="mr-2"
                            @click="
                                showContactDialog({
                                    deposit: item,
                                    screen: 'deposits',
                                })
                            "
                            v-on="on"
                            v-bind="attrs"
                            >mdi-account</v-icon
                        >
                    </template>
                    <span>Contato</span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.status == 0">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            v-on="on"
                            v-bind="attrs"
                            class="mr-2"
                            @click="
                                scheduleDeposit({
                                    deposit: item,
                                    screen: 'deposit',
                                })
                            "
                            >mdi-calendar</v-icon
                        >
                    </template>
                    <span>Agendado/Erro</span>
                </v-tooltip>
                <v-tooltip bottom v-if="showPixRelated(item)">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            v-on="on"
                            v-bind="attrs"
                            class="mr-2"
                            @click="
                                getRelatedPixes({
                                    deposit: item,
                                    screen: 'deposit',
                                })
                            "
                            >mdi-file-multiple</v-icon
                        >
                    </template>
                    <span>Pix relacionados</span>
                </v-tooltip>

                <!--v-tooltip
                    bottom
                    v-if="
                        item.status == 2 &&
                        (item.validation_state == 2 ||
                            item.validation_state == 0)
                    "
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            v-on="on"
                            v-bind="attrs"
                            class="mr-2"
                            @click="
                                getRelatedPixes({
                                    deposit: item,
                                    screen: 'deposit',
                                })
                            "
                            >mdi-file-multiple</v-icon
                        >
                    </template>
                    <span>Pix relacionados</span>
                </v-tooltip-->

                <v-tooltip
                    bottom
                    v-if="
                        !item.bonus_id &&
                        item.status == 2 &&
                        (item.payment_type == 0 || item.payment_type == 3)
                    "
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            v-on="on"
                            v-bind="attrs"
                            class="mr-2"
                            @click="
                                generateBonus({
                                    deposit: item,
                                    screen: 'deposit',
                                })
                            "
                            >mdi-wallet-giftcard</v-icon
                        >
                    </template>
                    <span>Gerar Bônus</span>
                </v-tooltip>
                <v-tooltip
                    bottom
                    v-if="
                        item.type_send == 5 &&
                        (item.type == 0 || item.type == 1)
                    "
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            v-on="on"
                            v-bind="attrs"
                            class="mr-2"
                            @click="findTransactionPrint({ item })"
                        >
                            mdi-image-area</v-icon
                        >
                    </template>
                    <span>Print</span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.status == 10">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            
                            class="mr-2"
                            color="green"
                            v-on="on"
                            v-bind="attrs"
                            @click="showDialogApproveOperation({ show: true, item })"
                            >mdi-cash-check</v-icon
                        >
                    </template>
                    <span>Aprovar Operação</span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.validation_state == 4 || item.status == 10">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            v-on="on"
                            v-bind="attrs"
                            class="mr-2"
                            color="red"
                            @click="
                                refuseDepositDuplicate({
                                    deposit: item,
                                    screen: 'deposit',
                                })
                            "
                            >mdi-cash-remove</v-icon
                        >
                    </template>
                    <span>Recusado/Duplicado</span>
                </v-tooltip>
            </template>
        </v-data-table>

        <v-dialog
            :value="dialog"
            @click:outside="closeDialog()"
            width="800"
            @keydown.esc="closeDialog()"
        >
            <deposit-form></deposit-form>
        </v-dialog>

        <v-dialog
            :value="dialogFilter"
            width="600"
            @click:outside="showFilter({ show: false })"
            @keydown.esc="showFilter({ show: false })"
            @keydown.enter="filter"
        >
            <deposit-filter></deposit-filter>
        </v-dialog>

        <v-dialog
            :value="dialogDelete"
            @keydown.esc="closeDeleteDialog"
            @click:outside="closeDeleteDialog"
            @keydown.enter="deleteItem"
            width="300"
        >
            <v-card>
                <v-card-title class="headline"
                    >Confirmar a exclusão?</v-card-title
                >

                <v-card-actions>
                    <v-col>
                        <v-btn text @click="closeDeleteDialog()"
                            >Cancelar</v-btn
                        >
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text type="submit" right @click="deleteItem"
                            >Confirmar</v-btn
                        >
                    </v-col>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            :value="dialogReorder"
            @keydown.esc="closeReorderDialog"
            @click:outside="closeReorderDialog"
            width="500"
        >
            <v-card>
                <v-card-title class="headline"
                    >Confirmar o reenvio de ordem ao robô?</v-card-title
                >

                <v-card-actions>
                    <v-col>
                        <v-btn text @click="closeReorderDialog()"
                            >Cancelar</v-btn
                        >
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text type="submit" right @click="reorder"
                            >Confirmar</v-btn
                        >
                    </v-col>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            :value="dialogSearchPix"
            @keydown.esc="showDialogSearchPix({ show: false })"
            @click:outside="showDialogSearchPix({ show: false })"
            width="500"
        >
            <deposit-search-pix></deposit-search-pix>
        </v-dialog>

        <v-dialog
            v-model="dialogReceipt"
            @keydown.esc="setDialogReceipt({ show: false })"
            width="90%"
            @click:outside="setDialogReceipt({ show: false })"
        >
            <v-card height="90vh">
                <v-card-title
                    v-if="depositReceipt.type == 2"
                    class="text-center justify-center"
                    style="color: red"
                    >ATENÇÃO !!!! ESSE COMPROVANTE É UM ABATE DE ANOTADOS !!!
                    NÃO ENVIE AS FICHAS !!!</v-card-title
                >
                <iframe
                    v-if="receiptMimeType == 'pdf'"
                    height="100%"
                    width="100%"
                    :src="receipt_show"
                ></iframe>
                <v-img
                    v-if="receiptMimeType != 'pdf'"
                    width="90%"
                    :max-height="depositReceipt.type == 2 ? '90%' : '100%'"
                    :src="receipt_show"
                    contain
                ></v-img>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogContact" max-width="500">
            <v-card>
                <contact-card
                    :contact_id="contact_show.phone"
                    :contact_name="contact_show.name"
                ></contact-card>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogAbate" persistent max-width="650">
            <v-card>
                <v-card-title class="headline text-center justify-center"
                    >ATENÇÃO</v-card-title
                >
                <v-card-title
                    >VOCÊ ACABOU DE FINALIZAR UM DEPÓSITO DO TIPO ABATE DE
                    ANOTADOS !!!!!</v-card-title
                >
                <v-card-title
                    >TENHA CERTEZA DE QUE NÃO REALIZOU NENHUM ENVIO DE FICHAS
                    PARA ESSA OPERAÇÃO !!!!!</v-card-title
                >

                <v-card-actions>
                    <v-col class="text-right">
                        <v-btn
                            text
                            type="submit"
                            right
                            @click="showDialogAbate({ show: false })"
                            >EU NÃO ENVIEI FICHAS</v-btn
                        >
                    </v-col>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            style="overflow: hidden"
            content-class="dialog-pix"
            :value="dialogPixRelated"
            @keydown.esc="showDialogPixRelateds({ show: false })"
            width="95%"
            @click:outside="showDialogPixRelateds({ show: false })"
        >
            <v-card>
                <v-card-title class="text-center justify-center"
                    >Relacionamento Pix - Deposito</v-card-title
                >
                <v-layout wrap>
                    <v-flex xs12 sm12 md12 xl12>
                        <template>
                            <v-data-table
                                :headers="headersPixRelated"
                                :items="[editedItem]"
                                :items-per-page="1"
                                hide-default-footer
                            >
                                <template v-slot:[`item.date`]="{ item }">
                                    <span>{{ getDate(item.date) }}</span>
                                </template>
                                <template
                                    v-slot:[`item.date_created`]="{ item }"
                                >
                                    <span>{{
                                        getDate(item.date_created)
                                    }}</span>
                                </template>
                                <template v-slot:[`item.finish_at`]="{ item }">
                                    <span>{{ getDate(item.finish_at) }}</span>
                                </template>
                                <template v-slot:[`item.value`]="{ item }">
                                    <span>{{
                                        item.value.toLocaleString('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL',
                                        })
                                    }}</span>
                                </template>
                                <template v-slot:[`item.status`]="{ item }">
                                    <span>{{ getStatus(item.status) }}</span>
                                </template>
                                <template
                                    v-slot:[`item.payment_type`]="{ item }"
                                >
                                    <span>{{
                                        getPaymentType(item.payment_type)
                                    }}</span>
                                </template>
                                <template v-slot:[`item.type`]="{ item }">
                                    <span>{{
                                        item.id_sx
                                            ? getType(item.type) + '-SX'
                                            : getType(item.type)
                                    }}</span>
                                </template>
                                <template
                                    v-slot:[`item.validation_state`]="{ item }"
                                >
                                    <v-chip
                                        :color="
                                            getColorValidation(
                                                item.validation_state,
                                            )
                                        "
                                        class="ma-2"
                                        :text-color="
                                            item.validation_state != 0
                                                ? 'white'
                                                : 'black'
                                        "
                                        label
                                        >{{
                                            getValidationState(
                                                item.validation_state,
                                            )
                                        }}</v-chip
                                    >
                                </template>
                                <template v-slot:[`item.sended`]="{ item }">
                                    <span>{{
                                        getSendedStatus(item.sended)
                                    }}</span>
                                </template>
                                <template v-slot:[`item.type_send`]="{ item }">
                                    <v-chip
                                        :color="
                                            getColorByTypeSend(item.type_send)
                                        "
                                        class="ma-2"
                                        :text-color="
                                            item.type_send != 0
                                                ? 'white'
                                                : 'black'
                                        "
                                        label
                                        >{{
                                            getSendType(item.type_send)
                                        }}</v-chip
                                    >
                                </template>
                            </v-data-table>
                        </template>
                    </v-flex>
                    <v-flex xs12 sm12 md4 xl4 class="mb-2">
                        <div class="pixes-related">
                            <v-card
                                class="mb-2 ml-2 mr-2 card-pix-related"
                                color="#385F73"
                                dark
                                v-for="pix in pix_relateds"
                                :key="pix.id"
                            >
                                <v-card-title>
                                    Cliente:
                                    {{
                                        pix.clientKey
                                            ? pix.clientKey
                                            : 'Não identificado'
                                    }}
                                </v-card-title>
                                <v-card-subtitle>
                                    <b>{{ pix.clientName }}</b>
                                    <br />
                                    <b>{{ pix.endToEndId }}</b>
                                    <br />
                                    {{
                                        'Valor: ' +
                                        pix.value.toLocaleString('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL',
                                        })
                                    }}
                                    <br />
                                    {{ 'Data: ' + getDate(pix.date) }}
                                    <br />
                                    {{
                                        'Utilizado: ' +
                                        (pix.deposit_id &&
                                        pix.deposit_id != null
                                            ? `Sim - ${pix.deposit_id}`
                                            : 'Não')
                                    }}
                                </v-card-subtitle>
                                <v-btn
                                    depressed
                                    v-if="
                                        editedItem.validation_state != 1 &&
                                        !pix.deposit_id
                                    "
                                    class="ml-2 mb-2"
                                    @click="
                                        editedItem.status == 2
                                            ? linkDepositFinished({ item: pix })
                                            : linkDeposit({ item: pix })
                                    "
                                    >Vincular Depósito</v-btn
                                >
                            </v-card>
                        </div>
                    </v-flex>
                    <v-flex xs12 sm12 md8 xl8>
                        <div
                            v-if="receiptMimeType != 'pdf'"
                            class="pixes-related-image"
                        >
                            <v-img
                                max-height="100%"
                                :src="receipt_show"
                                contain
                            ></v-img>
                        </div>
                        <div
                            v-if="receiptMimeType == 'pdf'"
                            class="pixes-related-frame"
                        >
                            <iframe
                                height="100%"
                                width="100%"
                                :src="receipt_show"
                            ></iframe>
                        </div>
                    </v-flex>
                </v-layout>
            </v-card>
        </v-dialog>

        <v-dialog
            :value="dialogRefuseDeposit"
            @keydown.esc="showDialogRefuseDeposit({ show: false })"
            @click:outside="showDialogRefuseDeposit({ show: false })"
            width="450"
        >
            <v-card>
                <v-card-title class="headline text-center justify-center">
                    Recusar Saque
                </v-card-title>
                <v-container grid-list-md>
                    <v-layout wrap>
                        <v-flex>
                            <v-textarea
                                auto-grow
                                required
                                label="Mensagem *"
                                v-model="message_refused"
                                :readonly="
                                    this.mode == 'VIEW' || this.mode == 'FINISH'
                                "
                                :error-messages="
                                    errors.collect('message_refused')
                                "
                                v-validate="'required:Mensagem,message_refused'"
                                data-vv-name="message_refused"
                                outlined
                                rows="2"
                            ></v-textarea>
                        </v-flex>
                    </v-layout>
                </v-container>
                <v-card-actions>
                    <v-col>
                        <v-btn
                            text
                            @click="showDialogRefuseDeposit({ show: false })"
                            >Cancelar</v-btn
                        >
                    </v-col>
                    <v-col class="text-right">
                        <v-btn
                            text
                            :disabled="block_refuse"
                            :readonly="block_refuse"
                            @click="
                                refuseDeposit({
                                    deposit: depositAutomatic,
                                    screen: 'deposit',
                                })
                            "
                            >Recusar</v-btn
                        >
                    </v-col>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            :value="dialogPrint"
            width="600"
            @click:outside="showDialogPrint({ show: false })"
        >
            <v-card height="350px">
                <v-img :src="print" contain></v-img>
            </v-card>
        </v-dialog>

        <v-dialog
            :value="dialogAddReceipt"
            width="600"
            @click:outside="closeDialogAddReceipt()"
        >
            <dialog-add-receipt></dialog-add-receipt>
        </v-dialog>

        <v-dialog
            :value="dialogApproveOperation"
            @click:outside="showDialogApproveOperation({ show: false })"
            width="600"
        >
            <v-card>
                <v-card-title class="headline text-center justify-center"
                    >Aprovar Operação</v-card-title
                >
                <v-data-table
                    :headers="[{text: 'Motivos', value: 'reason'}]"
                    :items="reasonsNeedApproval"
                    hide-default-footer
                ></v-data-table>
                <v-card-actions>
                    <v-col>
                        <v-btn
                            text
                            @click="showDialogApproveOperation({ show: false })"
                            >Cancelar</v-btn
                        >
                    </v-col>
                    <v-col class="text-right">
                        <v-btn
                            text
                            @click="approveOperation"
                            >Aprovar</v-btn
                        >
                    </v-col>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import DepositForm from './DepositForm'
import DepositFilter from './DepositFilter'
import DepositSearchPix from './DepositSearchPix'
import DialogAddReceipt from './DialogAddReceipt'
import ContactCard from '../../attendance/Chat/components/ContactCard'
// import JsonExcel from "vue-json-excel";
import { utils, writeFile } from 'xlsx'

export default {
    name: 'Deposits',
    computed: {
        ...mapState('login', ['userData']),
        ...mapState('bank', { banks: 'items' }),
        ...mapState('profile', ['profile']),
        ...mapState('deposit', [
            'avg_time',
            'contact_show',
            'dialog',
            'dialogAbate',
            'dialogDelete',
            'dialogReorder',
            'dialogReceipt',
            'depositReceipt',
            'dialogAddReceipt',
            'dialogSearchPix',
            'loadingSearchPix',
            'item',
            'instances',
            'errors',
            'loading',
            'mode',
            'notificationType',
            'notificationMessage',
            'rowsPerPage',
            'receipt_show',
            'totalItems',
            'items',
            'filtered',
            'pix_relateds',
            'totalPixes',
            'dialogRefuseDeposit',
            'depositRefused',
            'receiptMimeType',
            'editedItem',
            'print',
            'dialogPrint',
        ]),
        ...mapFields('deposit', [
            'dialogApproveOperation',
            'block_refuse',
            'depositRefused.message_refused',
            'pagination',
            'paginationPix',
            'dialogContact',
            'dialogPixRelated',
            'dialogFilter',
            'overlay',
            'showNotification',
            'total_value',
            'filter.code',
            'filter.bank',
            'filter.id_instance',
            'filter.start',
            'filter.end',
            'filter.value',
            'filter.status',
            'filter.id_pppoker',
            'filter.nick',
            'filter.type',
            'filter.payment_type',
            // ...
        ]),
        reasonsNeedApproval() {
            if(!this.editedItem?.need_approval_reason) return []
            let reasons = this.editedItem.need_approval_reason.split(';')
            return reasons.map((reason) => {
                return { reason: reason.replace(';', '') }
            })
        }
    },
    created() {
        this.getBanks()
        this.getSlots({
            resetPage: false,
            noPages: true,
            onlyIfNotSearched: true,
            inactive: false,
        })
        this.watchDeposits()
        this.setScreen({ screen: 'deposit' })
    },
    components: {
        'contact-card': ContactCard,
        // "download-excel": JsonExcel,
        'deposit-form': DepositForm,
        'deposit-filter': DepositFilter,
        'deposit-search-pix': DepositSearchPix,
        'dialog-add-receipt': DialogAddReceipt,
    },
    methods: {
        addDropFile(e) {
            if (e.dataTransfer) {
                if (e.dataTransfer.files[0]) {
                    this.receipt_file = e.dataTransfer.files[0]
                }
            }
        },
        ...mapActions('bank', { getBanks: 'getItems' }),
        ...mapActions('instances', ['getInstances']),
        ...mapGetters('login', ['checkProfileAccessManager']),
        ...mapActions('slots', ['getSlots']),
        ...mapMutations('deposit', [
            'add',
            'edit',
            'showFilter',
            'showDialogSearchPix',
            'showDialogPixRelateds',
            'setDialogReceipt',
            'showDialogAbate',
            'setScreen',
            'showDialogRefuseDeposit',
            'showDialogPrint',
            'showDialogApproveOperation'
        ]),
        ...mapActions('deposit', [
            'approveOperation',
            'closeDialog',
            'closeDeleteDialog',
            'closeReorderDialog',
            'closeDialogAddReceipt',
            'deleteItem',
            'reorder',
            'linkDeposit',
            'linkDepositFinished',
            'getRelatedPixes',
            'getItems',
            'getItemsNoPage',
            'getDepositTypes',
            'generateBonus',
            'showContactDialog',
            'showDeleteDialog',
            'showDialogReorder',
            'showDialogAddReceipt',
            'showReceipt',
            'validateDeposit',
            'finishDeposit',
            'startDeposit',
            'stopDeposit',
            'scheduleDeposit',
            'refuseDepositDuplicate',
            'downloadReceipts',
            'viewItem',
            'setDepositRefuse',
            'refuseDepositDialog',
            'refuseDeposit',
            'findTransactionPrint',
        ]),
        profilePermissions(param) {
            return this.profile[param] == 1
        },
        filter() {
            this.getItems({ resetPage: true })
            this.showFilter({ show: false })
        },
        isMobile() {
            if (
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent,
                )
            ) {
                return true
            }
            return false
        },
        getColorByTypeSend(type_send) {
            if (type_send == 0) {
                return 'gray'
            } else if (type_send == 1) {
                return 'cyan'
            } else if (type_send == 2 || type_send == 5) {
                return 'green'
            } else if (type_send == 3 || type_send == 4 || type_send == 6) {
                return 'red'
            }
        },
        getColorValidation(validation_state) {
            if (validation_state == 1 || validation_state == 6) {
                return 'indigo'
            } else if (validation_state == 2) {
                return 'orange'
            } else if (validation_state == 5 || validation_state == 4) {
                return 'red'
            }
        },
        getClassPixRelated(item) {
            if (item.deposit_id) {
                return 'row-green'
            } else {
                return 'row-blue'
            }
        },
        getDate(date) {
            if (date) {
                var d = new Date(date)
                return d.toLocaleDateString() + ' ' + d.toLocaleTimeString()
            }
            return ''
        },
        finishSheet() {
            this.overlay = false
        },
        generateSheet() {
            this.overlay = true
        },
        getSheetName() {
            var date = new Date()
            var name =
                'depositos_' +
                date.toLocaleDateString() +
                ' ' +
                date.toLocaleTimeString()
            return name + '.xls'
        },
        async downloadXlsx() {
            this.generateSheet()
            try {
                let itemsNoPage = await this.getItemsNoPage()
                if (itemsNoPage) {
                    const wb = utils.book_new()
                    let possible_status = [
                        'Pendente',
                        'Em Andamento',
                        'Concluído',
                        'Estornado',
                        'Validado',
                    ]
                    let payment_types = [
                        'A vista',
                        'Anotado',
                        'Troca de Ticket',
                        'Pix',
                    ]
                    let possible_types = [
                        'Envio de Fichas Jogador',
                        'Envio de Fichas Agência',
                        'Abater Anotados',
                        'Adicionar ao Saldo Positivo',
                        'Transferência',
                        'Bônus',
                        'Reembolso',
                    ]
                    let items = itemsNoPage.map((row) => ({
                        Banco: row.bank_name,
                        Data: this.dateXLSX(row.date),
                        Nome: row.name,
                        Valor: this.valueXLSX(row.value),
                        Clube: row.club_name,
                        'Id PPPoker': row.id_pppoker,
                        Nick: row.nick,
                        Status: possible_status[row.status],
                        Pagamento: payment_types[row.payment_type],
                        Fichas: possible_types[row.type],
                        'Criado Em': this.dateTimeXLSX(row.date_created),
                        'Finalizado Em': this.dateTimeXLSX(row.finish_at),
                    }))
                    const ws = utils.json_to_sheet(items)
                    utils.book_append_sheet(wb, ws, 'Depósitos')
                    await writeFile(wb, this.getSheetName())
                }
            } catch (error) {
                this.finishSheet()
            } finally {
                this.finishSheet()
            }
        },
        dateXLSX: (value) => {
            if (value) {
                var d = new Date(value)
                return d.toLocaleDateString()
            }
        },
        valueXLSX: (value) => {
            if (value) {
                let real = value.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                })
                return real.substr(3)
            }
            return 0
        },
        showPixRelated(item) {
            let show =
                item.payment_type == 3 &&
                (item.type_send == 0 || item.type_send == 5) &&
                item.status != 2 &&
                item.validation_state != 1 &&
                item.validation_state != 4 &&
                item.validation_state != 5
            return show
        },
        dateTimeXLSX: (value) => {
            if (value) {
                var d = new Date(value)
                return d.toLocaleDateString() + ' ' + d.toLocaleTimeString()
            }
        },
        getSendedStatus(type) {
            switch (type) {
                case 0:
                    return 'Pendente'
                case 1:
                    return 'Enviado'
                case 2:
                    return 'Recebido'
            }
        },
        getSendType(type) {
            switch (type) {
                case 0:
                    return 'Pendente'
                case 1:
                    return 'Solicitado'
                case 2:
                    return 'Realizado'
                case 3:
                    return 'Erro Bot'
                case 4:
                    return 'Manual - PB'
                case 5:
                    return 'Realizado - PB'
                case 6: 
                    return 'Duplicado'
            }
        },
        getPaymentType(type) {
            return this.payment_types[type]
        },
        getType(type) {
            return this.possible_types[type]
        },
        getStatus(status) {
            return this.possible_status[status]
        },
        getClassColor(item) {
            if (item.status == 2) {
                return 'row-green'
            } else if (item.status == 0) {
                if (item.validation_state == 1 || item.validation_state == 6) {
                    return 'row-blue'
                }
                return 'row-red'
            } else if (item.status == 5) {
                return 'row-yellow-schedule'
            } else if (item.status == 6) {
                if (item.type_send == 3) {
                    return 'row-refused'
                }
                return 'row-teal'
            } else if (item.status == 7) {
                return 'row-pink'
            } else if(item.status == 10) {
                return 'row-maroon'
            }
        },
        getValidationState(validation) {
            let validation_types = [
                'Pendente',
                'Automática',
                'Erro',
                'Não encontrado',
                'Duplicado',
                'Valor Diferente',
                'Manual - Sistema',
            ]
            return validation_types[validation]
        },
        watchDeposits() {
            if (this.mode == 'LIST') {
                this.getItems({ resetPage: false })
            }
            if (this.$router) {
                if (this.$router.history.current.name == 'deposits') {
                    setTimeout(() => {
                        this.watchDeposits()
                    }, 25000)
                }
            }
        },
    },
    data: () => ({
        bank_pix: 77,
        companies: [
            'Stars',
            'Aquarios',
            'Jacks',
            'Canubet',
            'Grupo Stars',
            'Stars Diamantes',
            'Outros',
        ],
        expanded: [],
        headersPixRelated: [
            { text: 'Id', value: 'id', sortable: false },
            { text: 'Banco', value: 'bank_name', sortable: true },
            { text: 'Valor', value: 'value', sortable: true },
            { text: 'Clube', value: 'club_name', sortable: true },
            { text: 'Id Suprema', value: 'id_pppoker', sortable: true },
            { text: 'Status', value: 'status', sortable: true },
            { text: 'Pagamento', value: 'payment_type', sortable: true },
            { text: 'Fichas', value: 'type', sortable: true },
            { text: 'Validação', value: 'validation_state', sortable: true },
            { text: 'Criado em', value: 'date_created', sortable: true },
        ],
        headers: [
            { text: 'Id', value: 'id', sortable: false },
            { text: 'Banco', value: 'bank_name', sortable: true },
            { text: 'Valor', value: 'value', sortable: true },
            { text: 'Clube', value: 'club_name', sortable: true },
            { text: 'Id Suprema', value: 'id_pppoker', sortable: true },
            { text: 'Status', value: 'status', sortable: true },
            { text: 'Pagamento', value: 'payment_type', sortable: true },
            { text: 'Fichas', value: 'type', sortable: true },
            { text: 'Validação', value: 'validation_state', sortable: true },
            { text: 'Status do Envio', value: 'type_send', sortable: true },
            { text: 'Criado em', value: 'date_created', sortable: true },
            { text: 'Finalizado em', value: 'finish_at', sortable: true },
            { text: '', value: 'data-table-expand' },
            {
                text: 'Ações',
                value: 'actions',
                sortable: false,
                align: 'center',
            },
        ],
        headersPix: [
            { text: 'CPF/CPNJ', value: 'client_key', sortable: true },
            {
                text: 'Data',
                align: 'left',
                value: 'date',
                sortable: true,
            },
            { text: 'Valor', value: 'value', sortable: true },
            { text: 'endToEndId', value: 'endToEndId', sortable: false },
            { text: 'txId', value: 'txId', sortable: false },
            { text: 'Utilizado', value: 'deposit_id', sortable: true },
            {
                text: 'Ações',
                value: 'actions',
                sortable: false,
                align: 'center',
            },
        ],
        possible_types: [
            'Envio Jogador',
            'Envio Agência',
            'Abater Anotados',
            'Adicionar ao Saldo Positivo',
            'Transferência',
            'Bônus',
            'Reembolso',
        ],
        possible_status: [
            'Pendente',
            'Em Andamento',
            'Concluído',
            'Estornado',
            'Aguardando Pagamento',
            'Agendado/Erro',
            'Validado',
            'Recusado',
            '',
            '',
            'Aguardando Aprovação'
        ],
        payment_types: [
            'Transferência',
            'Anotado',
            'Troca de Ticket',
            'Pix',
            'Bônus',
            'Ganhos/Perdas',
            'Fichas',
            'Reembolso',
        ],
        validation_types: [
            'Pendente',
            'Automática',
            'Manual',
            'Não encontrado',
            'Duplicado',
        ],
        sheet_fields: {
            Banco: {
                field: 'bank_name',
            },
            Data: {
                field: 'date',
                callback: (value) => {
                    if (value) {
                        var d = new Date(value)
                        return d.toLocaleDateString()
                    }
                },
            },
            Nome: {
                field: 'name',
            },
            Valor: {
                field: 'value',
                callback: (value) => {
                    if (value) {
                        let real = value.toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                        })
                        return real.substr(3)
                    }
                    return 0
                },
            },
            Clube: {
                field: 'club_name',
            },
            'Id Pppoker': {
                field: 'id_pppoker',
            },
            Nick: {
                field: 'nick',
            },
            Status: {
                field: 'status',
                callback: (value) => {
                    let possible_status = [
                        'Pendente',
                        'Em Andamento',
                        'Concluído',
                        'Estornado',
                        'Validado',
                    ]
                    return possible_status[value]
                },
            },
            Pagamento: {
                field: 'payment_type',
                callback: (value) => {
                    let payment_types = [
                        'A vista',
                        'Anotado',
                        'Troca de Ticket',
                        'Pix',
                    ]
                    return payment_types[value]
                },
            },
            Fichas: {
                field: 'type',
                callback: (value) => {
                    let possible_types = [
                        'Envio de Fichas Jogador',
                        'Envio de Fichas Agência',
                        'Abater Anotados',
                        'Adicionar ao Saldo Positivo',
                        'Transferência',
                        'Bônus',
                        'Reembolso',
                    ]
                    return possible_types[value]
                },
            },
            'Criado em': {
                field: 'date_created',
                callback: (value) => {
                    if (value) {
                        var d = new Date(value)
                        return (
                            d.toLocaleDateString() +
                            ' ' +
                            d.toLocaleTimeString()
                        )
                    }
                },
            },
            'Finalizado em': {
                field: 'finish_at',
                callback: (value) => {
                    if (value) {
                        var d = new Date(value)
                        return (
                            d.toLocaleDateString() +
                            ' ' +
                            d.toLocaleTimeString()
                        )
                    }
                },
            },
        },
        valid: false,
    }),
    watch: {
        pagination: {
            handler() {
                this.getItems({ resetPage: false })
            },
        },
        receipt_file: {
            handler() {
                this.setFileBase64()
            },
        },
    },
}
</script>

<style lang="sass">
.dialog-pix
    margin: 5px
    overflow-y: hidden !important

.pixes-related
    height: 60vh
    overflow-y: scroll

.pixes-related-image
    max-height: 60vh
    overflow: scroll

.pixes-related-frame
    height: 100%

.pixes-related::-webkit-scrollbar
    width: 8px
    background-color: #e4dcd4

.pixes-related::-webkit-scrollbar-thumb
    border-radius: 6px
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0)
    background-color: #e4dcd4

.pixes-related-image::-webkit-scrollbar
    width: 8px
    background-color: #e4dcd4

.pixes-related-image::-webkit-scrollbar-thumb
    border-radius: 6px
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0)
    background-color: #e4dcd4

.card-pix-related
    text-color: white !important

iframe::-webkit-scrollbar
    width: 8px
    background-color: #e4dcd4

iframe::-webkit-scrollbar-thumb
    border-radius: 6px
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0)
    background-color: #e4dcd4
</style>